import React, { useContext } from 'react';
import { Icon, Select, Badge } from 'antd';
import { Context } from '@context';
import { SESSIONSTATUS } from '@constant';
import { setIMSettingStore } from '@utils/storage';
import OnlineStatus from './OnlineStatus';
import CustomerInfo from './CustomerInfo';

import styles from './index.less';

const { Option } = Select;

const Header = props => {
  const { state, dispatch } = useContext(Context);
  const { sessionStatus, setSessionStatus } = props;

  const updateSystemInfo = payload => {
    dispatch({
      payload
    });
    setIMSettingStore(payload);
  };

  const onChange = status => {
    updateSystemInfo({ sessionStatus: status });
    setSessionStatus(status);
  };

  const { menuFold } = state;

  const newSessionStatus = sessionStatus === undefined ? '2' : sessionStatus;
  return (
    <div className={styles['im-header']}>
      <Icon
        className={styles.trigger}
        type={menuFold ? 'menu-fold' : 'menu-unfold'}
        onClick={() => updateSystemInfo({ menuFold: !menuFold })}
      />
      {/* <OnlineStatus />*/}
      <CustomerInfo />
      <div className={styles['session-status']}>
        <Select
          style={{ width: 77 }}
          onChange={onChange}
          value={newSessionStatus}
          size='large'
          showArrow={false}>
          {SESSIONSTATUS.map(item => (
            <Option value={item.code} disabled={item.disabled} key={item.code}>
              <div className={styles['inline-indicator']}>
                <span
                  style={{ background: item.color }}
                  className={styles['inline-indicator-circle']}
                />
                <span style={{ paddingLeft: '10px' }}>{item.status}</span>
              </div>
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default React.memo(Header);
