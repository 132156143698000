import React, { PureComponent } from 'react';
import Loading from '@components/Loading';

export default class AutoHeightIframe extends PureComponent {
  constructor() {
    super();
    this.state = {
      wrapperHeight: '60px',
      loading: true
    };
    console.log('process.env.NODE_ENV: ', process.env.NODE_ENV, process.env.IM_ENV);
    // this.host =
    //   process.env.NODE_ENV === 'production' && process.env.IM_ENV !== 'test'
    //     ? 'https://www.51shihuimiao.com/screen/im-userinfo/index.html'
    //     : 'https://beta.51shihuimiao.com/screen/im-userinfo/index.html';
    this.host = process.env.NODE_ENV === 'production' && process.env.IM_ENV !== 'test'
          ? 'https://cms.shmiao.net/external'
          : 'https://betacms.shmiao.net/external';
  }

  handleLoad() {
    const frame = this.refs.iframe;
    try {
      const height = frame.contentWindow.document.body.scrollHeight;
      this.setState({
        iFrameHeight: height + 'px',
        wrapperHeight: height + 20 + 'px',
        loading: false
      });
    } catch (error) {
      this.setState({
        loading: false,
        iFrameHeight: '100%',
        wrapperHeight: '90%',
      });
    }
  }

  render() {
    const { wrapperHeight, iFrameHeight, loading } = this.state;
    const { uid } = this.props;

    return (
      <div
        style={{
          height: wrapperHeight
        }}>
        {loading && <Loading />}
        <iframe
          title="用户信息"
          ref={c => (this.iframe = c)}
          style={{
            width: '100%',
            height: iFrameHeight,
            overflow: 'visible'
          }}
          onLoad={this.handleLoad.bind(this)}
          src={`${this.host}?user_id=${uid}&version=20220125`}
          width='100%'
          height={iFrameHeight}
          frameBorder='0'
        />
      </div>
    );
  }
}
