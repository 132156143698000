import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Menu, Popover } from 'antd';
import { noop } from 'lodash';
import Emoji from './emoji';
import Icon from '../Icon';
import './index.css';

export default class ChatEmoji extends PureComponent {
  static propTypes = {
    style: PropTypes.object,
    onClick: PropTypes.func
  };

  static defaultProps = {
    style: null,
    onClick: noop
  };

  state = {
    emojiPadding: 2,
    emojiWidth: 25,
    lineNum: 10,
    visible: false
  };

  componentDidMount() {
    document.addEventListener('click', this.handleBlur, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleBlur, false);
  }

  handleBlur = event => {
    const { target } = event;
    const role = target.getAttribute('data-role');
    if (role && role === 'popover') return;
    this.setState({ visible: false });
  };

  renderEmojiMenu = () => {
    const { emojiWidth, emojiPadding, lineNum } = this.state;
    const emojisNum = Object.values(Emoji.map).length;
    const rows = Math.ceil(emojisNum / lineNum);
    const width = (emojiWidth + 2 * emojiPadding) * lineNum;
    const height = (emojiWidth + 2 * emojiPadding) * rows;
    return (
      <Menu className='x-emoji' style={{ width, height }}>
        {this.renderEmoji()}
      </Menu>
    );
  };

  handleHidePopover = (k, event) => {
    const { src } = event.target;
    this.setState({ visible: false });
    this.props.onClick(k, src);
  };

  handleShowPopover = () => this.setState({ visible: true });

  renderEmoji = () => {
    const { emojiWidth, emojiPadding } = this.state;

    return Object.keys(Emoji.map).map(k => {
      const v = Emoji.map[k];
      return (
        <Menu.Item
          className='ib'
          key={k}
          style={{
            width: emojiWidth + 2 * emojiPadding,
            height: emojiWidth + 2 * emojiPadding,
            padding: emojiPadding,
            margin: 0
          }}>
          <img
            alt='moji'
            src={
              // eslint-disable-next-line
              require(`./faces/${v}`)
            }
            width={emojiWidth}
            height={emojiWidth}
            onClick={event => this.handleHidePopover(k, event)}
            data-role='popover'
            className='moji-img insertChatEditor'
          />
        </Menu.Item>
      );
    });
  };

  render() {
    const { visible } = this.state;
    const { style } = this.props;
    const menu = this.renderEmojiMenu();

    return (
      <div>
        <Popover content={menu} trigger={['click']} visible={visible}>
          <Icon
            onClick={this.handleShowPopover}
            data-role='popover'
            type='biaoqing-xue'
            style={style}
            className='insertChatEditor'
          />
        </Popover>
      </div>
    );
  }
}
