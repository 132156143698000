/**
 * Created by jimmy_zhou on 2019/5/9.
 */
import { get, post } from './http';
import {
  ReportCustomerServiceScreenSendMessageClick,
  ReportCustomerServiceScreenCreateSessionClick,
} from '@shm/interface/lib/reportevents/frontend/customerservicescreen';
import { logger } from '@shm/interface/lib/index.app.web';

// 获取总天数
const get1970ToDayCountWithMinusOneHour = (now = 0) => Math.floor((now || Date.now()) / 86400000);
const IM_STORAGE_CLIENT_COUNT_DAYS_KEY = 'IM_STORAGE_CLIENT_COUNT_DAYS_KEY';

let locaClientCountDays = {};
try {
  locaClientCountDays = localStorage.getItem(IM_STORAGE_CLIENT_COUNT_DAYS_KEY);
  if (locaClientCountDays) {
    locaClientCountDays = JSON.parse(locaClientCountDays);
  }
} catch (error) {
  //
}

if (!locaClientCountDays) {
  locaClientCountDays = {}
}

export default {
  getUserInfo(data) {
    return get('/userinfo', data);
  },
  getCsInfo(data) {
    return post('/user-list-by-appuid', data);
  },
  getCurrent(data) {
    return post('/current', data);
  },
  getOrderList(data) {
    return get('/historyorder', data);
  },
  queryQuickReply(data) {
    return get('/quickreplysearch', data);
  },
  queryKnowledgeBase(data) {
    return get('/knowledgebasesearch', data);
  },
  queryConsultCate(data) {
    return post('/query-cat-list', data);
  },
  postConsultCate(data) {
    return post('/upload-cat-info', data);
  },
  queryReplies(data) {
    return post('/fr-list', data);
  },
  queryMark(data) {
    return post('/get-customer-mark', data);
  },
  postMark(data) {
    return post('/add-customer-mark', data);
  },
  postReply(params, msgs = [], client) {
    const messages = msgs?.slice?.();
    if (messages.length) {
      const key1 = '此用户待接线, 自助回复:';
      const key2 = '此用户待接线, 原始消息为:';
      let action_name;
      const locaCountDays = locaClientCountDays[client] || 0;
      const currentDays = get1970ToDayCountWithMinusOneHour();
      let lastFindMessage;
      for (let i = messages.length - 1; i >= 0; i -= 1) {
        const message = messages[i];
        if (message?.c?.startsWith?.(key1)) {
          lastFindMessage = message;
          const content = message.c?.split(key1)?.[1];
          action_name = content.trim();
          break;
        } else if (message?.c?.startsWith?.(key2)) {
          lastFindMessage = message;
          const content = message.c?.split(key2)?.[1];
          action_name = content?.replace(/, 客服消息数:\d+/, '')?.trim();
          if (action_name) {
            action_name = `用户发送消息:${action_name}`;
          }
        }
      }
      if (lastFindMessage) {
        const messageDays = get1970ToDayCountWithMinusOneHour(lastFindMessage.t);
        if (currentDays > locaCountDays && messageDays === currentDays) {
          locaClientCountDays[client] = currentDays;
          params.action_name = action_name;
          window.localStorage.setItem(IM_STORAGE_CLIENT_COUNT_DAYS_KEY, JSON.stringify(locaClientCountDays));
        }
      }
    }
    ReportCustomerServiceScreenSendMessageClick(params);
  },
  postActiveSession(params) {
    ReportCustomerServiceScreenCreateSessionClick(params);
  },
  sentryExceptionMessageReport(sessionList, uid) {
    try {
      if (sessionList && sessionList.length) {
        // 最后更新时间大于二十分钟的
        const maxLastTime = 1000 * 60 * 20;
        // 最后更新时间小于2小时的
        const minLastTime = 1000 * 60 * 120;
        const now = Date.now();
        const exceptionMessage = sessionList.filter((sessionItem) => {
          if (!sessionItem.messages || !sessionItem?.messages?.length) return false;

          // 取出最后一条消息判断是否是用户发出的, 如果是客服发出的则不处理
          const lastMessage = sessionItem.messages[sessionItem.messages.length - 1];
          if (`${lastMessage.u}`.indexOf('cms-') !== -1 || !lastMessage.t) {
            return false;
          }

          // 如果是用户发出的消息则判断是否超过了20分钟并且小于2小时
          const diffTime = now - lastMessage.t;
          return diffTime > maxLastTime && diffTime < minLastTime;
        });
        if (exceptionMessage.length) {
          setTimeout(() => {
            logger.warn('im会话消息异常', 'im:session', {
              now,
              list: JSON.stringify(exceptionMessage.slice(0, 3).map((item) => {
                const messages = item.messages ? item.messages.slice(item.messages.length - 1) : [];
                return {
                  ...item,
                  messages,
                }
              }))
            });
          }, 1000);
        }
      }
    } catch (error) {
      //
    }
  }
};
