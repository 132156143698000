const data = [
  // {
  //   key: 1,
  //   name: "smartReply",
  //   type: '推荐回复',
  //   desc: '当用户有新消息时为客服推荐回复'
  // },
  {
    key: 2,
    name: "openNotify",
    type: '消息通知',
    desc: '当用户有新消息时，将会使用通知功能'
  }
];

export default data;