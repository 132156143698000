import { message } from 'antd';
import React, { useCallback } from 'react';
import styles from './index.less';

export const VideoSession = ({ url }) => {
    const onDownload = useCallback(() => {
        const hide = message.loading('正在下载...');
        fetch(url).then((res) => res.arrayBuffer()).then((buffer) => {
            const blob = new Blob([buffer], { type: 'video/mp4' });
            const downloadURL = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('download', `${downloadURL}.mp4`);
            link.setAttribute('href', downloadURL);
            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
                document.body.removeChild(link);
                window.URL.revokeObjectURL(downloadURL);
            }, 1000);
        }).finally(hide);
    }, [url]);

    return <div className={styles.videoSessionBox}>
        <video controls src={url}></video>
        <button onClick={onDownload}>点击下载</button>
    </div>;
};
