import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import { noop } from 'lodash';

import 'react-image-lightbox/style.css';
import './index.less';

export default class PreviewImage extends PureComponent {
  static propTypes = {
    source: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func
  };

  static defaultProps = {
    source: '',
    visible: false,
    onHide: noop
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: props.visible
    };
  }

  componentWillReceiveProps(nextProps) {
    const { visible } = nextProps;
    if (this.state.visible !== visible) {
      this.setState({ visible });
    }
  }

  onClose = () => {
    this.setState({ visible: false });
    this.props.onHide();
  };

  render() {
    const { visible } = this.state;
    const { source } = this.props;
    return (
      <>
        {visible ? (
          <Lightbox mainSrc={source} onCloseRequest={this.onClose} />
        ) : null}
      </>
    );
  }
}
