import React, { PureComponent, Fragment } from 'react';
import { noop, isEmpty } from 'lodash';
import moment from 'moment';
import { Icon, Pagination, Input } from 'antd';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Context } from '@context';
import './index.css';
import avatar from '../../assets/images/avatar.png';
import ImageComp from './Image';
import { STATE_RECALL, MESSAGETYPE, EMOJI } from '@constant';
import SearchList from './SearchList';
import { regExpObj } from '@utils';

const { emojiType } = regExpObj;
export default class SessionList extends PureComponent {
  static contextType = Context;

  static propTypes = {
    sessionList: PropTypes.array,
    onOpenSession: PropTypes.func,
    client: PropTypes.string,
    userInfo: PropTypes.object,
    IM: PropTypes.object,
    visible: PropTypes.bool,
  };

  static defaultProps = {
    sessionList: [],
    onOpenSession: noop,
    client: '',
    userInfo: {}
  };

  constructor(props) {
    super(props);
    this.handle(props);
    this.sessionListNode = React.createRef();
    this.timer = null;
    this.pageSize = 10;
    this.state = {
      pageNum: 1,
      // 搜索关键词
      keyword: '',
    };
  }

  componentDidMount() {
    this.renderCountdown();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  componentDidUpdate(props, props2) {
    if (!props.visible) {
      this.setState({
        keyword: '',
      });
    }
  }

  // 处理当client为空是，自动将会话列表的第一个对象的client赋值给当前client
  handle = props => {
    const { client, sessionList, onOpenSession } = props;
    if (!client && !isEmpty(sessionList)) {
      onOpenSession(sessionList[0].client);
    }
  };

  renderCountdown = () => {
    const node = this.sessionListNode.current;
    const els = node.getElementsByClassName('sessionlist-list-nores');
    for (let i = 0; i < els.length; i++) {
      const el = els[i];

      const duration = moment.duration(
        moment().diff(moment(+el.dataset.time.toString().slice(0, 13)))
      );
      const [days, hours, minutes, seconds] = [
        duration.get('days'),
        duration.get('hours'),
        duration.get('minutes'),
        duration.get('seconds')
      ];
      if (days) {
        el.innerText = `${days}天${hours}时${minutes}分${seconds}秒`;
      } else if (hours) {
        el.innerText = `${hours}时${minutes}分${seconds}秒`;
      } else if (minutes) {
        el.innerText = `${minutes}分${seconds}秒`;
      } else if (seconds) {
        el.innerText = `${seconds}秒`;
      }
    }
    this.timer = setTimeout(() => {
      this.renderCountdown();
    }, 1000);
  };

  ifShowCountdown = session => {
    // 未回复
    if (session.messages) {
      return (
        session.messages[session.messages.length - 1]['u'] === session.client
      );
    } else {
      return session.snap_msg.from_uid === session.client;
    }
  };

  ifLastMsgIsRecalled = session => {
    if (session.messages) {
      return (
        session.messages[session.messages.length - 1]['state'] === STATE_RECALL
      );
    } else {
      if (session.snap_msg) {
        if (session.snap_msg.state === STATE_RECALL) {
          return true
        }
      }
    }
    return false;
  };

  onChange = (page) => {
    this.setState({
      pageNum: page,
    });
  }

  /**
   * 
   * @param {string} keyword 根据关键词搜索会话中的消息
   */
  onSearchMessage = (keyword) => {
    this.setState({
      keyword,
    });
  }

  render() {
    const { sessionList, onOpenSession, client, userInfo, IM, visible } = this.props;
    const { pageNum, keyword } = this.state;
    const {
      state: { closeSession }
    } = this.context;
    // if (isEmpty(userInfo)) return null;
    return (
      <Fragment>
        <div className={classNames('sessionlist', {
          'sessionlist-search': keyword.length > 0,
        })} ref={this.sessionListNode}>
          {visible && <div className={'sessionlist-list-search'} >
            <Input.Search allowClear onSearch={this.onSearchMessage} placeholder="输入消息关键词以进行搜索" />
          </div>}
          {
            keyword.length > 0 && <SearchList sessionList={sessionList} client={client} keyword={keyword} IM={IM} onOpenSession={onOpenSession} />
          }
          {(sessionList && keyword.length === 0) &&
            sessionList.slice(this.pageSize * (pageNum - 1), this.pageSize * pageNum).map((item, index) => (
              <div
                // eslint-disable-next-line
                key={index}
                className={classNames('sessionlist-list', {
                  active: client === item.client
                })}
                onClick={() => onOpenSession(item.client)}>
                <div
                  className={`sessionlist-list-avatar ${
                    client === item.client ? 'active' : ''
                    }`}>
                  {~Object.keys(closeSession).indexOf(item.client) ? (
                    <Icon
                      type='close-circle'
                      style={{ color: '#f5222d', fontSize: '35px', opacity: 0.7 }}
                    />
                  ) : (
                      <ImageComp
                        src={
                          userInfo[item.client]
                            ? userInfo[item.client].avatar || avatar
                            : avatar
                        }
                        alt='avatr'
                        className='sessionlist-list-avatar-img'
                      />
                    )}
                </div>
                <div className='sessionlist-list-middle'>
                  <p className='sessionlist-list-middle-name'>
                    {/* {userInfo[item.client]
                    ? userInfo[item.client].nickname || ''
                    : ''} */}
                    {item.client}
                  </p>
                  <p className='sessionlist-list-middle-session'>
                    {this.ifLastMsgIsRecalled(item)
                      ? '[撤回]'
                      : item.last_content}
                  </p>
                </div>
                <div
                  className='sessionlist-list-unread'
                  style={{ display: item.unread ? 'block' : 'none' }}>
                  {item.unread}
                </div>
                <div className='sessionlist-list-time'>
                  {item.last_update &&
                    moment(+item.last_update.toString().slice(0, 13)).format(
                      'HH:mm'
                    )}
                </div>
                {this.ifShowCountdown(item) ? (
                  <div
                    className='sessionlist-list-nores'
                    data-time={item.last_update}
                  />
                ) : null}
                <div className='sessionlist-list-switch-time'>
                  {item.switch_in_time && `接入时间：${
                    moment(+item.switch_in_time.toString().slice(0, 13)).format(
                      'YYYY-MM-DD HH:mm:ss'
                    )}`}
                </div>
              </div>
            ))}
        </div>
        {keyword.length === 0 && <div className="sessionlist-list-pagination-wrapper">
          <div className="sessionlist-list-pagination-innerwrapper">
            <Pagination className='sessionlist-list-pagination' size="small" pageSize={this.pageSize} total={sessionList.length}  showTotal={(total) => `共${total}条`} current={pageNum} onChange={this.onChange} />
          </div>
        </div>}
      </Fragment>
    );
  }
}
