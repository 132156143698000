import { STORE_NAME } from '../constant';

export const setStore = (name, content, maxAge = null) => {
  if (!name) {
    return;
  }

  if (typeof content !== 'string') {
    content = JSON.stringify(content);
  }

  localStorage.setItem(name, content);
  if (maxAge && !isNaN(parseInt(maxAge))) {
    const timeout = parseInt(new Date().getTime() / 1000);
    localStorage.setItem(`${name}_expire`, timeout + maxAge);
  }
};

export const getStore = name => {
  if (!name) {
    return;
  }

  const content = localStorage.getItem(name);
  const _expire = localStorage.getItem(`${name}_expire`);

  if (_expire) {
    const now = parseInt(new Date().getTime() / 1000);
    if (now > _expire) {
      return;
    }
  }

  try {
    return JSON.parse(content);
  } catch (e) {
    return content;
  }
};

export const clearStore = name => {
  if (!name) {
    return;
  }

  localStorage.removeItem(name);
  localStorage.removeItem(`${name}_expire`);
};

export const clearAll = () => {
  localStorage.clear();
};

export const getIMSettingStore = () => {
  return (
    getStore(STORE_NAME) || {
      menuFold: true,
      openNotify: true
    }
  );
};

export const setIMSettingStore = config => {
  const setting = getIMSettingStore();
  setStore(STORE_NAME, {
    ...setting,
    ...config
  });
};

export const getCached = name => {
  return getStore(name) || {};
};

export const setCached = (name, config) => {
  const cached = getCached(name);
  setStore(name, {
    ...cached,
    ...config
  });
};
