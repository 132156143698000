import axios from 'axios';
import { getTokenInfo } from '@utils';

let baseURL;
if (process.env.NODE_ENV === 'development') {
  baseURL = '//techcenter-app-qchat-admin-api-qa.qttcs3.cn';
} else {
  if (process.env.IM_ENV === 'test') {
    baseURL = '//techcenter-app-qchat-admin-api-qa.qttcs3.cn';
  } else {
    baseURL = '//imcms-api.innotechx.com';
  }
}

// 创建 axios 实例
const http = axios.create({
  baseURL, // api base_url
  timeout: 6000 // 请求超时时间
});

const err = error => {
  return Promise.reject(error);
};

// request interceptor
http.interceptors.request.use(config => {
  let token = getTokenInfo();
  if (token !== null) {
    config.headers['Authorization'] = 'Bearer ' + token;
  }

  return config;
}, err);

http.interceptors.response.use(response => {
  return response.data;
}, err);

// export
export default http;
http.get = get;
http.post = post;

export function get(url, params) {
  return http({
    method: 'get',
    url,
    params
  });
}

export function post(url, data) {
  return http({
    method: 'post',
    url,
    data
  });
}
