import React from 'react';
import moment from 'moment';
import defaultAvator from '../../assets/images/avatar.png';

export default {
  columns: [
    {
      title: '头像',
      dataIndex: 'avatar',
      key: 'avatar',
      className: 'text-center',
      align: 'center',
      width: 60,
      render: (
        text // eslint-disable-line
      ) => (
        <img
          src={text || defaultAvator}
          alt='avatar'
          style={{
            width: 25,
            height: 25,
            borderRadius: '50%',
            verticalAlign: 'middle'
          }}
        />
      )
    },
    {
      title: '用户ID',
      dataIndex: 'uid',
      key: 'uid',
      className: 'text-left',
      width: 200,
    },
    {
      title: '消息预览',
      dataIndex: 'last_content',
      key: 'last_content',
      className: 'text-left overflow-ellipsis',
      render: (
        text // eslint-disable-line
      ) => (
        <div className='overflow-ellipsis' title={text}>
          {text}
        </div>
      )
    },
    {
      title: '消息时间',
      dataIndex: 'last_update',
      key: 'last_update',
      className: 'text-left',
      width: 170,
      render: (
        text // eslint-disable-line
      ) => (
        <span>
          {moment(+text.toString().slice(0, 13)).format('YYYY-MM-DD HH:mm:ss')}
        </span>
      )
    },
    {
      title: '操作',
      dataIndex: 'operating',
      key: 'operating',
      className: 'text-center',
      width: 260,
    }
  ]
};
