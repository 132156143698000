import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../../Icon';
import styles from './index.less';

export default class File extends PureComponent {
  static propTypes = {
    content: PropTypes.object,
    done: PropTypes.bool,
  }

  static defaultProps = {
    content: '',
    done: true,
  }

  render() {
    const { content, done } = this.props;
    return (
      <div className={styles.file}>
        <div className={styles.con}>
          <div className={styles.con_icon}>
            <Icon type="wenjian" style={{ fontSize: 52, color: '#f8f8f8' }} />
          </div>
          <div className={styles.con_name}>{content.name}</div>
        </div>
        <div
          className={classnames({
            [styles.loading]: true,
            [styles.hide]: done,
          })}
        >
          <Icon type="loading1" style={{ fontSize: 30, color: '#ccc' }} spin />
        </div>
      </div>
    );
  }
}
