import React, { Component } from "react";
import { Menu, Icon, Layout, Modal, message } from "antd";
import { isEmpty } from "lodash";
import classnames from "classnames";
import { Context } from "@context";
import PreviewImage from "@components/PreviewImage";
import Loading from "@components/Loading";
import ImHeader from "@components/Header";
import TotalUnread from "@components/TotalUnread";
import CurrentSession from "@pages/CurrentSession";
import SessionRecord from "@pages/SessionRecord";
import WaitingSessionList from "@pages/WaitingSessionList";
import Setting from "@pages/Setting";
import createIM from "@utils/im";
import notify from "@utils/notify";
import { setStore, clearStore } from "@utils/storage";

import api from "@services/api";
import styles from "./index.less";

import {
  // 推送的消息类型
  MESSAGETYPE,
  // 会话类型，会话列表
  SESSION_LIST,
  // 会话类型，当前待接入会话列表
  SESSION_WAITING_LIST,
  // 会话类型，历史会话列表
  SESSION_HISTORY_LIST,
  // 会话类型，会话设置
  SESSION_SETTING,
  // 当前会话状态
  SESSIONSTATUS,
  EMOJI,
} from "./constant";
import { regExpObj } from "./utils";

const { emojiType } = regExpObj;
const { Sider } = Layout;

export default class WebIMPage extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);

    this.state = {
      // websocket
      IM: null,
      // 会话列表
      session_list: [],
      // 当前正在进行会话的买家
      client: "",
      // 会话类型
      type: SESSION_LIST,
      // 关闭模态框显示与否
      close_modal_visible: false,
      // 会话状态
      session_status: "0",
      // 右侧边栏的Tabs的activeKey
      rightSiderActivekey: "1",
      // previewImage is show
      isShowPreviewImage: false,
      previewImageSource: "",
      // 聊天会话框滚动到什么位置 bottom: 底部  prevTop: 滚动到拉去历史记录之前的顶部位置 false表示不滚动
      chatRoomScrollTo: false,
      // 会话历史记录查询是否已经结束
      isEndHistorySessionRecord: true,
      userInfo: {},
      // 通过搜索打开session传入的用户ID
      searchMsgClient: '',
    };
  }

  async componentDidMount() {
    this.initIM();
    this.initNotify();
  }

  // 初始化IM实例
  initIM = () => {
    const IM = createIM((session_list, login_info) => {
      const { status, max_serve } = login_info;
      session_list = session_list || [];

      IM.queryTodayMsg().then((s2) => {
        s2 = s2 || []
        for (let i = 0; i < s2.length; i++) {
          let s = s2[i];

          let index = session_list.findIndex((item) => {
            return item.peer.uid === s.uidEnc
          })
          if (index !== -1) {
            continue
          }
          session_list.push(
            {
              last_update: s.last_created,
              client: s.uidEnc,
              msg_fetched: false,
              offset: s.last_msg_id,
              peer: { "uid": s.uidEnc },
              snap_msg: {
                app: 4,
                created: s.last_created,
                ext: s.ext,
                from_uid: s.from_uid,
                msg_id: s.last_msg_id,
                state: s.state,
                to_uid: s.to_uid,
                type: s.last_msg_type,
                content: s.last_content,
              }
            }
          )
        }
        const sessionList = this.getUserList(session_list, false) || []
        sessionList.sort((a, b) => b.last_update - a.last_update);
        this.setState({
          session_list: sessionList,
        });
      }).catch(() => {
        const sessionList = this.getUserList(session_list, false) || []
        sessionList.sort((a, b) => b.last_update - a.last_update);
        this.setState({
          session_list: sessionList,
        });
      })

      this.setState({
        IM,
        session_status: status,
      });
      if (status != 0) {
        message.warning(
          `您现在处于${
          (status == 2 || status === undefined) ? "离线" : "挂起"
          }状态。正常进线请切换至在线状态`
        );
      }
      const session_status = sessionStorage.getItem("session_status");
      if (status != 2 && session_status && session_status != status) {
        IM.onSetStatus(session_status);
        this.setState({ session_status });
        sessionStorage.clear("session_status");
      }
    });
    IM.on("session:send:reply", this.handleSendReply);
    IM.on("session:receive", this.handleReceivedMessage);
    IM.on("session:history:reply", this.handleHistoryReply);
    IM.on("session:close:reply", this.handleCloseReply);
    IM.on("session:switch:reply", this.handleSwitchReply);
    IM.on("session:setStatus:reply", this.handleStatusReply);
    IM.on("session:updateOffset:reply", this.handleUpdateOffsetReply);
    IM.on("session:transferout", this.handleSessionTransferOut);
    IM.on("session:transferin", this.handleSessionTransferIn);
    IM.on("session:transferclose", this.beforeSessionTransferClose);
    IM.on("session:transferclose:actually", this.handleSessionTransferClose);
    IM.on("session:dial:reply", this.handleDialReply);
    IM.on("session:pick:reply", this.handleDialReply);
    IM.on("session:msgupdate:recall", this.handleRecall);

    const { dispatch } = this.context;
    dispatch({
      payload: { IM },
    });
  };

  initNotify = () => {
    this.pageVisibility = document.visibilityState;
    document.addEventListener("visibilitychange", () => {
      this.pageVisibility = document.visibilityState;
      if (document.visibilityState == "visible") {
        notify.clearTimer();
      }
    });
  };

  notify = (last_content, uid) => {
    setTimeout(() => {
      const {
        state: { openNotify, totalUnread },
      } = this.context;
      // const userInfo = this.state.userInfo[uid] || {};
      if (openNotify && totalUnread > 0 && this.pageVisibility !== "visible") {
        notify.setTitle(`商家客服(${totalUnread})`);
        notify.notify({
          title: "新消息",
          body: `${uid}:${last_content}`,
        });
      }
    });
  };

  genTotalUnread() {
    setTimeout(() => {
      const { session_list } = this.state;
      const {
        dispatch,
      } = this.context;

      const totalUnread = session_list.reduce((acc, cur) => {
        if (cur.unread) {
          return acc + cur.unread
        }
        return acc
      }, 0)
      dispatch({
        payload: {
          totalUnread
        }
      });
    });
  }

  // 手动关闭会话 手动设置会话状态 手动转接会话 这几个操作 都会有新的用户转接心来，所以需要做一些处理
  // handleSwitchReply | handleStatusReply | handleCloseReply
  getUserList = (data) => {
    if (isEmpty(data)) return;
    const result = [];
    const uids = [];
    data.forEach((item) => {
      const option = {
        ...item,
        last_content:
          MESSAGETYPE.find((it) => it.type === item.snap_msg.type)?.content ||
          item.snap_msg.content?.replace(emojiType, (match) => {
            const ifMatch = EMOJI.find((emoji) => emoji.name === match);
            if (ifMatch) {
              return ifMatch.text;
            }
            return match;
          }),
        client: item.peer.uid,
        unread: item.snap_msg.msg_id - item.offset,
      };
      uids.push(option.client);
      result.push(option);
    });
    // this.getUserInfo(uids);
    this.genTotalUnread();
    return result;
  };

  getUserInfo = async (uids) => {
    const { payload: userInfo } = await api.getUserInfo({
      uids: uids.toString(),
    });
    this.setState({ userInfo });
  };

  // 回拨的回调函数 | 待接入会话转接
  handleDialReply = (data) => {
    const { session_list } = this.state;
    const index = session_list.findIndex(
      (item) => item.client === data.peer.uid
    );
    let sessionList =
      index < 0 ? [...this.getUserList([data]), ...session_list] : session_list;
    this.setState(
      {
        type: SESSION_LIST,
        session_list: sessionList,
      },
      () => {
        // session_list不可以同时修改。在CurrentSession中处理会报错
        this.setState({ client: data.peer.uid });
      }
    );
  };

  // 会话转出
  handleSessionTransferOut = (data) => {
    const { uid } = data;
    const { session_list } = this.state;
    const sessionList = session_list.filter((item) => item.client !== uid);
    this.setState({
      session_list: sessionList,
      client: this.getNextSessionClient(uid),
    });
  };

  // 会话转入
  handleSessionTransferIn = (data) => {
    const { session_list } = this.state;
    const sessionList = [...this.getUserList([data]), ...session_list];
    this.setState({
      session_list: sessionList,
    });
  };

  // 获取当前这个client === uid的sessionItem下一个sessionItem的client
  getNextSessionClient = (uid) => {
    const { session_list } = this.state;
    const currentIndex = session_list.findIndex((item) => item.client === uid);
    if (currentIndex < 0) return null;
    const len = session_list.length;
    if (currentIndex >= len - 1) {
      if (len > 0) {
        return session_list[0].client;
      }
      return null;
    }
    return session_list[currentIndex + 1].client;
  };

  // 会话自动关闭
  beforeSessionTransferClose = (data) => {
    const { uid } = data;
    const { dispatch } = this.context;

    dispatch({
      type: "ADD_CLOSE_SESSION",
      payload: {
        [uid]: data,
      },
    });
  };

  handleSessionTransferClose = (data) => {
    const { cs_threads, uid } = data;
    const { session_list, client, userInfo } = this.state;
    const sessionItem = session_list.find((item) => item.client === uid);
    // 这条会话不存在，或者是出错了
    if (!sessionItem) return;
    const sessionList = session_list.filter((item) => item.client !== uid);
    const newUserList = this.getUserList(cs_threads);
    const nextClient = this.getNextSessionClient(uid);
    const state = {
      session_list: newUserList ? newUserList.concat(sessionList) : sessionList,
    };
    // 如果会话关闭接口有延迟，当接口回调触发时当前窗口已经不是之前的那个用户了，那么client不会发生变化
    if (client === uid) {
      state.client = nextClient;
      if (nextClient) {
        const sessionList = state.session_list;
        const idx = sessionList.findIndex((item) => item.client === nextClient);
        sessionList[idx].unread = 0;
        this.genTotalUnread();
      }
    }
    this.setState(state);
  };

  // 接收到会话消息
  handleReceivedMessage = (data, placeTop = true) => {
    const {
      from_uid,
      content,
      timestamp,
      type,
      msg_id,
      to_csid,
      to_uid,
    } = data;

    const { session_list, client: currClient, IM } = this.state;

    // 当条件满足时，不会加入到会话列表中，二期加入到待接入会话列表
    if (to_csid && !to_uid) return;

    const imUID = IM.getLoginInfo().uid;
    // 打开多个tab
    const client = from_uid === IM.getLoginInfo().uid ? to_uid : from_uid;

    const sessionList = [...session_list];
    const idx = sessionList.findIndex((item) => item.client === client);
    let sessionItem;

    if (idx >= 0) {
      sessionItem = { ...sessionList.splice(idx, 1)[0] };
      // 如果是当前会话窗口返回的信息，那么不现实未读数量
      if (client === currClient) {
        sessionItem.unread = 0;
      } else {
        sessionItem.unread += 1;
      }
      sessionItem.snap_msg.msg_id = msg_id;
    } else {
      sessionItem = {
        client,
        unread: 1,
        // snap_msg.msg_id 当获取用户的会话记录的时候会用到
        snap_msg: { msg_id },
      };
    }
    // 客户发送的信息会经过处理展示在会话列表中
    const messageType = MESSAGETYPE.find((item) => item.type === type);
    sessionItem.last_content =
      messageType.content ||
      content.replace(emojiType, (match) => {
        const ifMatch = EMOJI.find((it) => it.name === match);
        if (ifMatch) {
          return ifMatch.text;
        }
        return match;
      });
    // 将返回的信息塞到对应买家的messages中
    sessionItem.messages = sessionItem.messages || [];
    sessionItem.messages.push({
      c: content,
      u: from_uid,
      t: timestamp,
      ct: messageType.ctype,
      id: msg_id,
    });
    sessionItem.last_update = timestamp;
    // 放到会话列表顶部
    if (idx >= 0 && !placeTop) {
      sessionList.splice(idx, 0, sessionItem);
    } else {
      sessionList.unshift(sessionItem);
      api.postActiveSession({
        sender: "1",
        sender_id: client,
      });
    }

    api.postReply({
      sender: "1",
      sender_id: client,
      quick_reply: "0",
      contains_pictures: "0",
      receiver_id: client,
    }, sessionItem?.messages, client);

    try {
      api.sentryExceptionMessageReport(sessionList, imUID);
    } catch (error) {
      //
    }

    this.setState(
      {
        session_list: sessionList,
      },
      () => {
        this.notify(sessionItem.last_content, client);
        // 如果服务器推送的是当前窗口的会话，那么就滚动到顶部
        if (client === currClient) {
          this.setState({ chatRoomScrollTo: "bottom" });
        }
      }
    );

    this.genTotalUnread();

    const {
      dispatch,
      state: { closeSession },
    } = this.context;

    if (~Object.keys(closeSession).indexOf(client)) {
      dispatch({
        type: "REMOVE_CLOSE_SESSION",
        payload: client,
      });
    }
  };

  // 开启个人会话
  handleStartSession = () => {
    const { session_list, client, IM } = this.state;
    const sessionList = [...session_list];
    const idx = sessionList.findIndex(
      (item) => item.client === (client || this.client)
    );
    const sessionItem = { ...sessionList.splice(idx, 1)[0] };
    // 更新当前会话的已经读取的 offset 位置
    IM.onUpdateOffset(sessionItem.snap_msg.msg_id, client);
    sessionList.splice(idx, 0, sessionItem);
    this.setState({ session_list: sessionList });
  };

  // 更新已读位置
  handleUpdateOffsetReply = (status, uid) => {
    if (status === "FAIL") return;
    const { session_list, total_unread } = this.state;
    const sessionList = [...session_list];
    const idx = sessionList.findIndex((item) => item.client === uid);
    const sessionItem = { ...sessionList.splice(idx, 1)[0] };
    // 当前未读消息总数 = 之前的未读消息总数 - 正在打开的这个用户会话的未读数
    const totalUnread = total_unread - sessionItem.unread;
    sessionItem.unread = 0;
    sessionList.splice(idx, 0, sessionItem);
    this.setState({ session_list: sessionList, total_unread: totalUnread });
    this.genTotalUnread();
  };

  // 会话的历史记录
  handleHistoryReply = async (data, isInit) => {
    const {
      state: { IM, csInfo },
      dispatch,
    } = this.context;
    const cs_uid = IM.getLoginInfo().uid;
    // 如果是第一次获取历史记录， 那么就先执行开启个人会话
    if (isInit) {
      this.handleStartSession();
    }
    const { session_list, client } = this.state;
    // 当messages=[]或者不存在的时候，就没有会话记录了
    if (isEmpty(data)) {
      this.setState({ isEndHistorySessionRecord: true });
      return;
    }

    const sessionList = [...session_list];
    const idx = sessionList.findIndex(
      (item) => item.client === (client || this.client)
    );
    const sessionItem = { ...sessionList.splice(idx, 1)[0] };
    const cs_uid_to_query = new Set();
    const list = data
      .map((item) => {
        const uid = client === item.from_uid ? item.to_uid : item.from_uid;
        if (cs_uid != uid && uid && !csInfo[uid] && !cs_uid_to_query.has(uid)) {
          cs_uid_to_query.add(uid);
        }
        return {
          c: item.content,
          u: item.from_uid,
          // 后台返回的created字段是一个19位数字时间戳
          t: +item.created.toString().slice(0, 13),
          ct: MESSAGETYPE.find((it) => it.type === item.type)?.ctype,
          id: item.msg_id,
          state: item.state,
        };
      })
      .reverse();
    if (isInit) {
      sessionItem.messages = [];
    }
    sessionItem.messages = sessionItem.messages
      ? list.concat(sessionItem.messages)
      : list;
    // 如果是第一次查看会话，那么肯定是要滚动到底部的, 否则就是历史记录，仍在当前位置
    const chatRoomScrollTo = isInit ? "bottom" : "prevTop";

    sessionList.splice(idx, 0, sessionItem);
    this.setState(
      {
        session_list: sessionList,
        chatRoomScrollTo: false,
      },
      () => this.setState({ chatRoomScrollTo, isEndHistorySessionRecord: true })
    );
    if (cs_uid_to_query.size !== 0) {
      let { data: cs_new } = await api.getCsInfo({
        app_uid: [...cs_uid_to_query],
      });
      cs_new = cs_new || [];
      const cs_to_merge = Object.create(null);
      cs_new.forEach((item) => {
        cs_to_merge[item["app_uid"]] = item;
      });
      dispatch({
        payload: {
          csInfo: {
            ...csInfo,
            ...cs_to_merge,
          },
        },
      });
    }
  };

  handleRecall = ({ msg }) => {
    const { state, msg_id } = msg;
    const { client, session_list } = this.state;

    if (!client) return;
    const sessionList = [...session_list];
    const idx = sessionList.findIndex((item) => item.client === client);
    if (idx === -1) return;
    const sessionItem = { ...sessionList.splice(idx, 1)[0] };
    const msgIdx = sessionItem.messages.findIndex((item) => item.id === msg_id);
    if (msgIdx === -1) return;
    sessionItem.messages[msgIdx].state = state;
    sessionList.splice(idx, 0, sessionItem);
    this.setState({ session_list: sessionList });
  };

  // 手动关闭会话
  handleCloseReply = (data) => {
    const { session_list, client } = this.state;
    let sessionList = session_list.filter((item) => item.client !== client);
    // 得到的新的用户列表
    const newUserList = this.getUserList(data);
    if (newUserList) sessionList = newUserList.concat(sessionList);
    this.setState({
      session_list: sessionList,
      client: this.getNextSessionClient(client),
    });
  };

  // 获取当前这个client === uid的sessionItem下一个sessionItem的client
  getNextSessionClient = (uid) => {
    const { session_list } = this.state;
    const len = session_list.length;
    // 只剩一个
    if (len === 1) {
      return null;
    }
    const currentIndex = session_list.findIndex((item) => item.client === uid);
    if (currentIndex < 0 || currentIndex === len - 1) {
      return session_list[0].client;
    }
    return session_list[currentIndex + 1].client;
  };

  // 人工发起会话转接 reply
  handleSwitchReply = (data) => {
    const { session_list, client } = this.state;
    let sessionList = session_list.filter((item) => item.client !== client);
    // 得到的新的用户列表
    const newUserList = this.getUserList(data);
    if (newUserList) sessionList = newUserList.concat(sessionList);
    this.setState({
      session_list: sessionList,
      client: this.getNextSessionClient(client),
    });
  };

  // 手动设置当前会话状态
  setSessionStatus = (status) => {
    const { session_status, IM } = this.state;
    if (status === session_status) {
      return;
    }
    this.setState({ session_status: status });
    if (status == 2) {
      IM.closeWs();
      const customerInfo = document.getElementById("customer-info").innerText;
      setStore("offline", customerInfo);
      setTimeout(() => {
        window.location.reload();
      }, 100);
    } else {
      clearStore("offline");
      if (session_status == 2) {
        sessionStorage.setItem("session_status", status);
        setTimeout(() => {
          window.location.reload();
        }, 100);
      } else {
        IM.onSetStatus(status);
      }
    }
  };

  // 手动设置当前会话状态 reply
  handleStatusReply = (status, data) => {
    const { session_list, session_status } = this.state;

    if (status === "FAIL") {
      const { code } = SESSIONSTATUS.filter(
        (item) => item.code !== session_status
      )[0];
      this.setState({ session_status: code });
      return;
    }

    let list = session_list;

    if (!isEmpty(data)) {
      list = this.getUserList(data).concat(list);
    }

    list.sort((a, b) => b.last_update - a.last_update);
    this.setState({ session_list: list });
  };

  // 发送一条消息 reply
  handleSendReply = (data) => {
    const { id: for_id, status, lastContent, lastTimeStamp, msg_id } = data;

    const { client, session_list } = this.state;
    if (!client) return;
    const sessionList = [...session_list];
    const idx = sessionList.findIndex((item) => item.client === client);

    const sessionItem = { ...sessionList.splice(idx, 1)[0] };
    // 解构sessionItem中的messages
    const messages = [...sessionItem.messages];
    // 找到messages中的目标对象的index
    const objIdx = messages.findIndex((item) => item.id === for_id);

    if (objIdx >= 0) {
      // 删除这个目标对象，并解构
      const obj = { ...messages.splice(objIdx, 1)[0] };
      // 如果状态成功，那么就执行下面操作。 否则就直接跳过
      if (status === "SUCCESS") {
        // 删除for_id属性，文本消息中含有for_id
        if (obj.hasOwnProperty("for_id")) {
          delete obj.for_id;
        } else if (obj.hasOwnProperty("done")) {
          // 文件， 图片等含有done 表示是否上传成功
          obj.done = true;
        }
        obj.id = msg_id;
        // 将obj再插入到messages中的原先的位置
        messages.splice(objIdx, 0, obj);
        sessionItem.last_content = lastContent.replace(emojiType, (match) => {
          const ifMatch = EMOJI.find((it) => it.name === match);
          if (ifMatch) {
            return ifMatch.text;
          }
          return match;
        });
        sessionItem.last_update = lastTimeStamp;
        sessionItem.snap_msg.msg_id += 1;
      } else {
        obj.fail = true;
        messages.splice(objIdx, 0, obj);
      }
      sessionItem.messages = messages;
    }
    sessionList.splice(idx, 0, sessionItem);
    this.setState({ session_list: sessionList });
  };

  // 退出登陆
  handleSignOut = () => {
    Modal.confirm({
      title: "消息提示",
      content: "确定要推出客服吗",
      okText: "确认",
      cancelText: "取消",
      onOk: () => window.close(),
    });
  };

  // 当会话状态自动发生变化
  handleSessionStatusChange = (data) => {
    const { value } = data;
    if (value === "0") {
      this.state.IM.close();
      Modal.warning({
        title: "提示",
        content: "您已在别处登录",
      });
    }
    this.setState({ session_status: value });
  };

  // 发送消息
  send = () => {
    // const { IM } = this.state;
    // IM.json(data);
  };

  // 点击左侧的菜单按钮
  handleMenu = (data) => {
    const { key } = data;
    const map = {
      1: SESSION_LIST,
      2: SESSION_WAITING_LIST,
      3: SESSION_HISTORY_LIST,
      4: SESSION_SETTING,
    };
    const type = map[key];
    this.setState({ type });
  };

  // 隐藏PreviewImage
  handleHidePreviewImage = () => this.setState({ isShowPreviewImage: false });

  // 当自组件需要修改state的时候，触发的事件
  handleChangeState = (data, fn) => {
    this.setState({ ...data, searchMsgClient: data.searchMsgClient || '' }, () => {
      if (typeof fn === "function") fn();
    });
  };

  render() {
    const {
      // 创建的socket对象
      IM,
      // 当前会话列表
      session_list,
      client,
      // 会话类型
      type,
      // 关闭的模态框是否显示
      // 所有会话用户的的avatar和昵称
      userInfo,
      // 常用语
      commonreply,
      // 右侧边栏 Tabs 的activekey
      rightSiderActivekey,
      // 常用语查询结果列表
      commonreplysearchResultList,
      // 用户订单
      userOrders,
      // PreviewImage is show
      isShowPreviewImage,
      // previewImage 的 source
      previewImageSource,
      // 当前会话状态
      session_status,
      mall_name,
      logo,
      // 聊天室滚到什么位置
      chatRoomScrollTo,
      // 获取历史会话记录是否已经结束
      isEndHistorySessionRecord,
      selectedTransferId,
      searchMsgClient,
    } = this.state;

    const {
      state: { menuFold },
    } = this.context;
    if (!IM) {
      return <Loading size="large" tip="初始化..." />;
    }

    return (
      <Layout className="im">
        <Sider
          trigger={null}
          collapsible
          collapsed={menuFold}
          width="175"
          className={styles["sider"]}
        >
          <div
            className={classnames({
              [styles.logo]: true,
              [styles["logo-collapsed"]]: menuFold,
            })}
          ></div>
          <Menu
            theme="dark"
            selectedKeys={[type]}
            mode="inline"
            onClick={this.handleMenu}
          >
            <Menu.Item key="1">
              <Icon type="message" style={{ fontSize: 24, marginLeft: -3 }} />
              <span>
                <span className={styles["menu-item-text"]}>当前会话</span>
              </span>
              <TotalUnread />
            </Menu.Item>
            <Menu.Item key="2">
              <Icon type="import" style={{ fontSize: 24, marginLeft: -3 }} />
              <span className={styles["menu-item-text"]}>待接入</span>
            </Menu.Item>
            <Menu.Item key="3">
              <Icon
                type="clock-circle"
                style={{ fontSize: 24, marginLeft: -3 }}
              />
              <span className={styles["menu-item-text"]}>历史记录</span>
            </Menu.Item>
            <Menu.Item key="4">
              <Icon type="setting" style={{ fontSize: 24, marginLeft: -3 }} />
              <span className={styles["menu-item-text"]}>设置</span>
            </Menu.Item>
          </Menu>
        </Sider>

        <Layout>
          <ImHeader
            sessionStatus={session_status}
            setSessionStatus={this.setSessionStatus}
          />
          <CurrentSession
            IM={IM}
            sessionList={session_list}
            client={client}
            userInfo={userInfo}
            commonreply={commonreply}
            rightSiderActivekey={rightSiderActivekey}
            commonreplysearchResultList={commonreplysearchResultList}
            userOrders={userOrders}
            onChange={this.handleChangeState}
            chatRoomScrollTo={chatRoomScrollTo}
            isEndHistorySessionRecord={isEndHistorySessionRecord}
            visible={type === SESSION_LIST}
            searchMsgClient={searchMsgClient}
          />
          {type === SESSION_WAITING_LIST ? (
            <Layout>
              <WaitingSessionList IM={IM} />
            </Layout>
          ) : null}
          {type === SESSION_HISTORY_LIST ? (
            <Layout>
              <SessionRecord
                IM={IM}
                visible={type === SESSION_HISTORY_LIST}
                logo={logo}
                onChange={this.handleChangeState}
              />
            </Layout>
          ) : null}
          {type === SESSION_SETTING ? (
            <Layout>
              <Setting />
            </Layout>
          ) : null}
        </Layout>
        <PreviewImage
          visible={isShowPreviewImage}
          onHide={this.handleHidePreviewImage}
          source={previewImageSource}
        />
      </Layout>
    );
  }
}
