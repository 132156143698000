import React, { memo, useState, Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import defaultImage from '../../assets/images/defaultImage.png';

function ImageComp(props) {
  const [ visible, setVisible ] = useState(false);
  const { src, alt, className, style } = props;
  return (
    <Fragment>
      <img
        src={defaultImage}
        alt={alt}
        className={`${className} ${!visible ? '' : 'hide'}`}
        style={style}
      />
      <img
        src={src}
        alt={alt}
        className={`${className} ${visible ? '' : 'hide'}`}
        style={style}
        onLoad={() => setVisible(true)}
      />
    </Fragment>
  );
}

ImageComp.PropTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

ImageComp.defaultProps = {
  alt: '',
  className: '',
  style: null,
};

export default memo(ImageComp);

