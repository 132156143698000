import React from 'react';
import { Tabs } from 'antd';
import UserInfo from './UserInfo';

const TabPane = Tabs.TabPane;

const Tab = ({ uid }) => {
  return (
    <div className='rightSider-tab'>
      <Tabs defaultActiveKey='1' size='small'>
        <TabPane tab='用户资料' key='1'>
          <UserInfo uid={uid} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default React.memo(Tab);
