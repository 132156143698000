import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { List, Pagination } from "antd";
import SearchItem from './SearchItem';

const SearchList = ({ client, keyword, IM, onOpenSession, sessionList }) => {
	const [dataSource, setDataSource] = useState([]);
	const [loading, setLoading] = useState(false);
	const [pageSize, setPageSize] = useState(1);

	const scopeUids = useMemo(() => (sessionList || []).map((session) => session.client), [sessionList]);
	const scopeUidsRef = useRef(scopeUids);
	scopeUidsRef.current = scopeUids;

	// 根据关键词搜索消息
	const fetchSearchMessageBykeyword = useCallback((searchKeyword) => {
		setLoading(true);
		const date = new Date();
		IM.searchAggsUser({
      // 搜索关键词
      content: searchKeyword,
      // 商户ID
      csid: IM.getLoginInfo().csid,
      // 客服ID
      csuid: IM.getLoginInfo().uid,
      // 用户范围：1.会话中；2.待接入
      user_scope: 1,
			count: 500,
			// 消息时间段
			time_range: `${date.getTime() - 259200000}000000,${date.getTime()}000000`,
			// 当前会话列表ID
			scope_uids: scopeUidsRef.current,
    }).then((data) => {
			setDataSource(data.payload || []);
		}).finally(() => {
			setLoading(false);
		});
	}, []);

	// 渲染消息item
	const onRenderItem = useCallback((item) => {
		return <SearchItem keyword={keyword} onOpenSession={onOpenSession} client={client} item={item} />;
	}, [client, onOpenSession, keyword]);

	const onRowKey = useCallback((item) => {
		const [msg] = item.msg_hits || [];
		return msg ? msg.content : Math.random();
	}, []);

	// 搜索关键词触发更新重新自动请求
	useEffect(() => {
		fetchSearchMessageBykeyword(keyword);
	}, [fetchSearchMessageBykeyword, keyword]);

  return (
		<React.Fragment>
			<div className={'sessionlist-search-panel'}>
				<List loading={loading} rowKey={onRowKey} dataSource={dataSource} renderItem={onRenderItem} />
			</div>
			<div className="sessionlist-list-pagination-wrapper">
				<div className="sessionlist-list-pagination-innerwrapper">
					<Pagination
						className='sessionlist-list-pagination'
						size="small"
						pageSize={pageSize}
						total={dataSource.length}
						showTotal={(total) => `共${total}条`}
						current={pageSize}
						onChange={setPageSize} />
				</div>
			</div>
		</React.Fragment>
	);
};

SearchList.propTypes = {
  client: PropTypes.string,
	keyword: PropTypes.string,
	IM: PropTypes.object,
	onOpenSession: PropTypes.func,
	sessionList: PropTypes.array,
};

export default SearchList;
