import React, { useContext, useCallback, useEffect, useState } from 'react';
import { Dropdown, Menu, Icon } from 'antd';
import Cookies from 'js-cookie';
import { AUTH_JWT_TOKEN_NAME } from '@constant';
import { Context } from '@context';
import api from '@services/api';
import { isOffline } from '@utils';
import { getStore, clearStore } from '@utils/storage';
import styles from './index.less';

const CustomerInfo = () => {
  const {
    state: { IM }
  } = useContext(Context);
  const [customerInfo, setCustomerInfo] = useState('');

  useEffect(() => {
    const query = async () => {
      const { data } = await api.getCsInfo({
        app_uid: [IM.getLoginInfo().uid]
      });
      if(data){
        setCustomerInfo(data[0].nick || data[0].app_uid);
      }
    };
    if (isOffline()) {
      setCustomerInfo(getStore('offline'));
    } else {
      query();
    }
  }, []);

  const handleSignOut = useCallback(() => {
    Cookies.remove(AUTH_JWT_TOKEN_NAME);
    clearStore('offline');
    window.location.href = 'https://imcms.innotechx.com';
  }, []);

  const menu = (
    <Menu>
      <Menu.Item key='1' onClick={handleSignOut}>
        <Icon type='logout' />
        退出
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles['customer-service']}>
      <Dropdown overlay={menu}>
        <span>
          <span id='customer-info' className={styles['customer-info']}>{customerInfo}</span> <Icon type='down' />
        </span>
      </Dropdown>
    </div>
  );
};

export default React.memo(CustomerInfo);
