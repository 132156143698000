import React from 'react';
import DragM from 'dragm';

class Draggable extends React.Component {
  updateTransform = transformStr => {
    this.modalDom.style.transform = transformStr;
  };
  componentDidMount() {
    this.modalDom = document.querySelector(`.${this.props.wrapClassName}`);
  }
  render() {
    const { title } = this.props;
    return (
      <DragM updateTransform={this.updateTransform}>
        <div>{title}</div>
      </DragM>
    );
  }
}

export default Draggable;
