import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Layout, Table, Modal, Button } from 'antd';
import api from '@services/api';
import Icons from '@components/Icon';

import {
  // 推送的消息类型
  MESSAGETYPE,
  EMOJI
} from '@constant';
import { regExpObj, isOffline } from '@utils';

import constant from './constant';
import styles from './index.less';

const { emojiType } = regExpObj;
const { columns } = constant;

export default class WaitingSessionList extends PureComponent {
  static propTypes = {
    IM: PropTypes.object.isRequired
  };

  constructor() {
    super();
    const column = [...columns];

    const operating = column.find(item => item.key === 'operating');
    operating.render = (text, record) => (
      <>
        <Icons
          type='Group7'
          className={styles.icon}
          onClick={() => this.handleTransferSession(record.uid)}>
          接入会话
        </Icons>
        <Icons
          type='GroupCopy1'
          className={styles.icon}
          onClick={() => this.handleCloseSession(record.uid)}>
          关闭会话
        </Icons>
      </>
    );

    this.state = {
      source: [],
      // 当前页码
      pageNum: 1,
      // 最大页
      maxPage: 1,
      // 是否在加载中
      column
    };
    this.since = [0];
  }

  componentDidMount() {
    if(!isOffline()) {
      this.query();
    }
  }

  // 处理queue返回的数据
  handleQueueResponseData = async data => {
    if (!data) {
      return;
    }
    const { pageNum } = this.state;
    const { queued_threads, more } = data;

    const uids = [];
    const source = [];
    queued_threads.forEach((item, index) => {
      uids.push(item.peer.uid);
      source.push({
        last_update: item.last_update,
        last_content:
          MESSAGETYPE.find(it => it.type === item.snap_msg.type)?.content ||
          item.snap_msg.content?.replace(emojiType, match => {
            const ifMatch = EMOJI.find(emoji => emoji.name === match);
            if (ifMatch) {
              return ifMatch.text;
            }
            return match;
          }),
        uid: item.peer.uid
      });
    });
    // const userInfo = await this.getUserInfo(uids);
    // source.forEach(item => {
    //   item.avatar = userInfo[item.uid].avatar;
    //   item.nickname = userInfo[item.uid].nickname;
    // });

    if (!more) {
      this.setState({ maxPage: pageNum, source });
    } else {
      this.setState({ maxPage: pageNum + 1, source });
      this.since.push(queued_threads[queued_threads.length - 1].wait_since);
    }
  };

  getUserInfo = async uids => {
    const { payload } = await api.getUserInfo({
      uids: uids.toString()
    });
    return payload;
  };

  query = () => {
    this.props.IM.onQueue(this.since[this.state.pageNum - 1])
      .then(data => {
        this.handleQueueResponseData(data);
      })
      .catch(error => {});
  };

  // 接入会话
  handleTransferSession = uid => {
    Modal.confirm({
      title: '提示',
      content: '确定要接入会话吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        this.props.IM.onPick(uid);
      }
    });
  };

  // 关闭会话
  handleCloseSession = uid => {
    Modal.confirm({
      title: '提示',
      content: '确定要关闭会话吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        this.props.IM.onCloseWaitingSession(uid, () => {
          const { source, pageNum } = this.state;
          if (source.length === 1 && pageNum !== 1) {
            this.handlePrevPage();
          } else {
            this.query();
          }
        });
      }
    });
  };

  // 上一页
  handlePrevPage = () => {
    const { pageNum } = this.state;
    this.setState({ pageNum: pageNum - 1 }, this.query);
  };

  // 下一页
  handleNextPage = () => {
    const { pageNum } = this.state;
    this.setState({ pageNum: pageNum + 1 }, this.query);
  };

  render() {
    const { source, column, pageNum, maxPage } = this.state;
    return (
      <Layout style={{ position: 'relative' }}>
        <div className={styles.waitingSessionList}>
          <div>
            <Table
              dataSource={source}
              columns={column}
              rowKey='uid'
              bordered
              pagination={false}
              size='middle'
            />
          </div>
          <div className={styles.footer}>
            <Button
              style={{ marginRight: '20px' }}
              onClick={this.handlePrevPage}
              disabled={pageNum <= 1}>
              上一页
            </Button>
            <span className={styles.currentPage}>
              第&nbsp;
              {pageNum}
              &nbsp;页
            </span>
            <Button
              type='primary'
              onClick={this.handleNextPage}
              disabled={pageNum >= maxPage}>
              下一页
            </Button>
          </div>
        </div>
      </Layout>
    );
  }
}
