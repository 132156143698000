import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Layout,
  message,
  Modal,
  Dropdown,
  Menu,
  Icon as AIcon
} from 'antd';
import classnames from 'classnames';
import { noop, isEmpty } from 'lodash';
import moment from 'moment';
import SessionList from '@components/Sessionlist';
import ChatEmoji from '@components/Chatemoji';
import ChatRoom from '@components/ChatRoom';
import UploadFile from '@components/UploadFile';
import QuickReply from '@components/QuickReply';
// import AudioRecorder from '@components/AudioRecorder';
import MediaQuery from '@components/MediaQuery';
import KeepSession from '@components/KeepSession';
import SessionTransfer from '@components/SessionTransfer';
import SessionTerminator from '@components/SessionTerminator';
// import Evaluation from '@components/Evaluation';
import Tabs from '@components/Tabs';
import Icon from '@components/Icon';
import { Context } from '@context';
import { compressImage, regExpObj } from '@utils';
import { dserialize } from '@utils/serialize';
import Queue from '@utils/queue';
import { LIMIT_FILE_SIZE } from '@constant';
import api from '@services/api';

import styles from './index.less';
import { QuickEntry } from '../../components/QuickEntry';

const { Header, Sider } = Layout;

export default class CurrentSession extends PureComponent {
  static contextType = Context;

  static propTypes = {
    IM: PropTypes.object,
    sessionList: PropTypes.array,
    client: PropTypes.string,
    userInfo: PropTypes.object,
    commonreply: PropTypes.array,
    rightSiderActivekey: PropTypes.string,
    commonreplysearchResultList: PropTypes.array,
    userOrders: PropTypes.array,
    onChange: PropTypes.func,
    chatRoomScrollTo: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    isEndHistorySessionRecord: PropTypes.bool,
    visible: PropTypes.bool,
    searchMsgClient: PropTypes.string,
  };

  static defaultProps = {
    IM: null,
    sessionList: [],
    client: '',
    userInfo: {},
    commonreply: [],
    rightSiderActivekey: '',
    commonreplysearchResultList: [],
    userOrders: [],
    onChange: noop,
    chatRoomScrollTo: false,
    isEndHistorySessionRecord: true,
    visible: false
  };

  constructor(props) {
    super(props);
    this.state = {
      targetElement: null,
      currentHistoryID: 0,
      ifChatRoomShow: false,
      imageModalVisible: false,
      imageSource: '',
      replies: [],
      // 通过搜索打开的session client
      searchMsgClient: '',
    };
    this.isEndHistorySessionRecord = props.isEndHistorySessionRecord;
    this.ifFirstOpenSession = true;
    this.candidateReplies = [];
    this.replyQuery = '';
    // 根据搜索的消息ID定位滚动位置
    this.searchChatboxItemDom = null;
    // 等待搜索关键词定位的最大时间
    this.waitSearchMsgClientTime = false;
  }

  componentWillReceiveProps(nextProps) {
    const {
      sessionList,
      chatRoomScrollTo,
      client,
      isEndHistorySessionRecord
    } = nextProps;
    const targetObj = sessionList.find(item => item.client === client);

    if (targetObj && targetObj.messages) {
      chatRoomScrollTo && this.scrollToTargetPosition(chatRoomScrollTo);
    }
    // 当获取历史记录结束以后
    if (isEndHistorySessionRecord) {
      this.isEndHistorySessionRecord = true;
    }
    // 当 client 发生变化时，
    if (client && this.props.client !== client) {
      this.setState({
        searchMsgClient: nextProps.searchMsgClient,
      }, () => {
        this.searchChatboxItemDom = null;
        this.sessionCheck(client);
        this.waitSearchMsgClientTime = setTimeout(() => {
          if (!this.state.searchMsgClient) return;
          this.setState({
            searchMsgClient: '',
          });
        }, 2000);
      });
    }
  }

  componentWillUnmount() {
    if (this.waitSearchMsgClientTime) {
      clearTimeout(this.waitSearchMsgClientTime);
    }
  }

  async componentDidMount() {
    const { IM } = this.props;
    let { data } = await api.queryReplies({
      cs_id: IM.getLoginInfo().csid
    });
    data = data ? data.map(d => {
      d.content = dserialize(d.content);
      return d;
    }) : [];
    this.candidateReplies = data;
  }

  // 获取后缀名
  getSuffixName = type => type.split('/')[1];

  // 获取历史会话记录
  handleHistorySessionRecord = () => {
    if (this.currentHistoryID <= 0) {
      return;
    };
    const { IM, client, onChange } = this.props;
    // 立刻设置为false，防止用户滚动到顶部，再次触发 获取历史会话记录 的事件
    this.isEndHistorySessionRecord = false;
    // 获取当前的最后一条记录，当获取到历史记录以后，回滚到之前的位置，看 scrollToTargetPosition 方法
    // 提前获取，不放在setState中获取，因为是异步的，当更新时，历史记录可能已经返回了
    const targetElement = this.chatRoom.children[2];
    const query = {
      fromID: this.currentHistoryID + 1,
      toID: this.currentHistoryID - 21,
      max: 20,
      peerID: client
    };
    IM.getSessionRecord(query, false).then(({ msg_list }) => {
      const { searchMsgClient } = this.state;
      const newMsgList = msg_list || [];
      if (!searchMsgClient || !newMsgList.length) return;
      const isNotFindMessage = newMsgList.every((item) => item.msg_id !== Number(searchMsgClient));
      if (isNotFindMessage) {
        this.handleHistorySessionRecord();
      }
    });
    
    this.currentHistoryID = query.toID;
    this.setState({ targetElement, currentHistoryID: this.currentHistoryID });
    // 传递给容器组件
    onChange({ isEndHistorySessionRecord: false });
  };

  // 通知容器组件修改client，当该组件接收到新的client值时就会触发 sessionCheck 方法。
  handleOpenSession = (uid, searchMsgClient) => {
    const { onChange } = this.props;
    if (this.ifFirstOpenSession) {
      this.ifFirstOpenSession = false;
    } else {
      this.setState({
        ifChatRoomShow: true
      });
    }
    onChange({
      client: uid,
      rightSiderActivekey: '1',
      searchMsgClient,
    });
  };

  back2SessionList = () => {
    this.setState({
      ifChatRoomShow: false
    });
  };

  // 发起个人会话请求
  sessionCheck = uid => {
    const { IM, sessionList } = this.props;
    const { searchMsgClient } = this.state;
    const {
      snap_msg: { msg_id }
    } = sessionList.filter(item => item.client === uid)[0];
    const query = {
      // 因为from_msg_id 是开区间
      fromID: msg_id + 1,
      // 因为to_msg_id 是开区间
      toID: msg_id - 21,
      max: 20,
      peerID: uid
    };
    // 获取历史记录
    IM.getSessionRecord(query, true).then(({ msg_list }) => {
      const newMsgList = msg_list || [];
      const isNotFindMessage = newMsgList.every((item) => item.msg_id !== Number(searchMsgClient));
      if (searchMsgClient && isNotFindMessage && newMsgList.length) {
        this.handleHistorySessionRecord(true);
      }
    });
    // 每次请求时都需要设置，当再次拉取历史记录的时候会使用
    this.currentHistoryID = query.toID;
    this.setState({ currentHistoryID: this.currentHistoryID });
  };

  filterRepeatMessage = (messages) => {
    if (!messages.length) return messages;
    var hasMsgID = [];
    const newMessages = messages.filter((message) => {
      if (hasMsgID.includes(message.id)) {
        return false;
      }
      hasMsgID.push(message.id);
      return true;
    });
    newMessages.sort((a, b) => {
      return a.t - b.t;
    });
    return newMessages;
  }

  // 渲染当前买家的会话消息列表
  renderCurSessionMessages = () => {
    const { client, sessionList, userInfo } = this.props;
    let customerAvatar;
    let content;
    if (client) {
      const idx = sessionList.findIndex(item => item.client === client);
      content = (idx >= 0 ? sessionList[idx].messages : []) || [];
      if (!isEmpty(userInfo) && userInfo[client]) {
        customerAvatar = userInfo[client].avatar;
      } else {
        customerAvatar = '';
      }
    } else {
      content = [];
    }
    return this.filterRepeatMessage(content).map(msg => (
      <ChatRoom
        key={`${client}:${JSON.stringify(msg)}`}
        message={msg}
        client={client}
        customerAvatar={customerAvatar}
        onClickImage={this.handleShowPreviewImage}
      />
    ));
  };

  // 展示PreviewImage
  handleShowPreviewImage = source => {
    this.props.onChange({
      isShowPreviewImage: true,
      previewImageSource: source
    });
  };

  // 在光标位置插入节点
  handleCursorPositionInsertElement = domElement => {
    // 得到焦点
    this.chatEditor.focus();
    const range = window.getSelection();
    if (!this.lastEditRange) {
      this.lastEditRange = range.getRangeAt(0);
    }
    const { childNodes } = this.chatEditor;
    const {
      // 光标开始位置
      // endOffset,
      // 光标结束位置
      startOffset,
      // 光标是在那个元素中，分为两种情况。
      // 1: 如果光标是卡在编辑文本中，那么commonAncestorContainer就是 this.chatEditor 子文本节点
      // 2: 如果光标不是卡在编辑文本中，那么commonAncestorContainer就是 this.chatEditor 这个节点
      commonAncestorContainer: cAC
    } = this.lastEditRange;
    // 光标在开始位置
    if (startOffset === 0 && cAC.nodeType === 1) {
      this.chatEditor.insertBefore(domElement, childNodes[0]);
      // 将光标移动到新插入的元素的后面
      range.collapse(this.chatEditor, 1);
      // 重新计算光标的位置
      this.lastEditRange = range.getRangeAt(0);
      return;
    }
    // 光标不在头部位置，那么这个时候可以通过判断光标是卡在编辑文本中
    if (cAC.nodeType === 1) {
      // 条件存在表示光标在中间某个位置，否则就是在末尾
      if (childNodes[startOffset]) {
        this.chatEditor.insertBefore(domElement, childNodes[startOffset]);
        // 将光标移动到新插入的元素的后面
        range.collapse(this.chatEditor, startOffset + 1);
      } else {
        this.chatEditor.appendChild(domElement);
        // 将光标移动到最后，在调用 collapseToEnd 之前，必须先调用 selectAllChildren方法
        range.selectAllChildren(this.chatEditor);
        range.collapseToEnd();
      }
      // 重新计算光标的位置
      this.lastEditRange = range.getRangeAt(0);
    } else if (cAC.nodeType === 3) {
      // 计算出当前光标所在的文本是第几个childNodes
      let idx = 0;
      if (!cAC.previousSibling) {
        idx = 0;
      } else if (!cAC.nextSibling) {
        idx = childNodes.length - 1;
      } else {
        idx = [].findIndex.call(childNodes, function(item) {
          let leftEle = false;
          let rightEle = false;
          if (item.nodeValue === cAC.nodeValue) {
            if (item.nextSibling) {
              if (cAC.nextSibling.nodeType === 1) {
                if (cAC.nextSibling.nodeName.toUpperCase() === 'BR') {
                  leftEle =
                    cAC.nextSibling.nodeName === item.nextSibling.nodeName;
                } else {
                  leftEle = cAC.nextSibling.id === item.nextSibling.id;
                }
              } else if (cAC.nextSibling.nodeType === 3) {
                leftEle =
                  cAC.nextSibling.nodeValue === item.nextSibling.nodeValue;
              }
            } else {
              leftEle = false;
            }

            if (item.previousSibling) {
              if (cAC.previousSibling.nodeType === 1) {
                if (cAC.previousSibling.nodeName.toUpperCase() === 'BR') {
                  rightEle =
                    cAC.previousSibling.nodeName ===
                    item.previousSibling.nodeName;
                } else {
                  rightEle = cAC.previousSibling.id === item.previousSibling.id;
                }
              } else if (cAC.previousSibling.nodeType === 3) {
                rightEle =
                  cAC.previousSibling.nodeValue ===
                  item.previousSibling.nodeValue;
              }
            } else {
              rightEle = false;
            }
          }
          return leftEle && rightEle;
        });
      }
      // 创建一个文档碎片
      const fragment = document.createDocumentFragment();
      // 将原先的文本节点拆分为左右两个部分，中间插入emoji
      const leftText = document.createTextNode(
        cAC.nodeValue.slice(0, startOffset)
      );
      const rightText = document.createTextNode(
        cAC.nodeValue.slice(startOffset)
      );
      fragment.appendChild(leftText);
      fragment.appendChild(domElement);
      fragment.appendChild(rightText);
      // 现将原先的这个文本节点删除
      this.chatEditor.removeChild(this.chatEditor.childNodes[idx]);
      // 往当前的这个元素的地方插入。注意如过当前idx的元素不存在，这个时候就和appendChild一样
      this.chatEditor.insertBefore(fragment, this.chatEditor.childNodes[idx]);
      // 将光标移动到插入的emoji后面。这里加2 是因为之前的文本被拆开并插入img元素。所以需要加2
      range.collapse(this.chatEditor, idx + 2);
      this.lastEditRange = range.getRangeAt(0);
    }
  };

  // 点击 常用语 复制到对话框中
  handleCopyTextToChatEditor = text => {
    const textElement = document.createTextNode(text);
    this.handleCursorPositionInsertElement(textElement);
  };

  inertElementToChatEditor = tagName => {
    const el = document.createElement(tagName);
    this.handleCursorPositionInsertElement(el);
  };

  // 点击 emojo 添加到对话框中
  handleAddEmojiToChatEditor = (text, src) => {
    const img = document.createElement('img');
    img.src = src;
    img.className = 'emoji';
    img.id =
      Math.random()
        .toString()
        .slice(2) + Date.now();
    img.width = 25;
    img.height = 25;
    img.setAttribute('data-rule', text);
    // img.setAttribute('data-id', Math.random().toString().slice(2) + new Date());
    img.setAttribute('alt', text);
    img.setAttribute('style', 'vertical-align: middle; margin: 0 2px;');
    this.handleCursorPositionInsertElement(img);
  };

  handleAddImgToChatEditor = src => {
    const main = document.createElement('main');
    main.className = 'reply-img-wrapper';
    const img = document.createElement('img');
    img.src = src;
    img.width = 60;
    img.height = 60;
    main.appendChild(img);
    this.handleCursorPositionInsertElement(main);
  };

  // 文件上传成功后，再推送一条message消息
  handleFileUploadComplete = data => {
    const { sessionList, client, onChange } = this.props;
    const newSessionList = [...sessionList];
    const idx = newSessionList.findIndex(item => item.client === client);
    const sessionItem = { ...newSessionList.splice(idx, 1)[0] };
    const messages = sessionItem.messages ? [...sessionItem.messages] : [];
    messages.push(data);
    sessionItem.messages = messages;
    newSessionList.unshift(sessionItem);
    onChange({ session_list: newSessionList }, this.scrollToTargetPosition);
  };

  // 滚动到底部
  scrollToTargetPosition = (position = 'bottom') => {
    if (position === 'bottom' && !this.state.searchMsgClient) {
      if (this.chatRoom) {
        this.chatRoom.scrollTop = this.chatRoom.scrollHeight;
      }
    } else if (this.state.searchMsgClient){
      this.searchChatboxItemDom = this.searchChatboxItemDom || document.querySelector(`.index_chatbox_item[data-msg-id="${this.state.searchMsgClient}"]`);
      this.searchChatboxItemDom && this.searchChatboxItemDom.scrollIntoView(true);
      if (this.searchChatboxItemDom) {
        const contentDom = this.searchChatboxItemDom.querySelector('.index_chatbox_content');
        if (contentDom) {
          contentDom.style.border = '1px solid red';
        }
      }

      if (this.searchChatboxItemDom) {
        this.setState({
          searchMsgClient: '',
        });
      }
      setTimeout(() => {
        this.chatRoom.scrollTop = this.chatRoom.scrollTop - 50
      }, 100);
    } else if (!this.state.searchMsgClient){
      const { targetElement } = this.state;
      targetElement && targetElement.scrollIntoView(true);
    }
    this.props.onChange({ chatRoomScrollTo: false });
  };

  // 将富文本中的信息罗列出来
  computed = element => {
    const childs = element.childNodes;
    const ret = [];
    let temp = '';

    for (let i = 0; i < childs.length; i++) {
      const item = childs[i];
      const { nodeType, nodeName } = item;
      // 先判断是文本节点还是元素节点
      if (nodeType === 1) {
        if (nodeName.toUpperCase() === 'IMG') {
          if (item.classList.contains('emoji')) {
            temp += item.getAttribute('data-rule');
          } else {
            ret.push({
              type: 'img',
              content: item.src
            });
          }
        } else if (nodeName.toUpperCase() === 'BR') {
          temp += '\n';
        } else if (nodeName.toUpperCase() === 'DIV') {
          temp += item.innerText;
        } else if (nodeName.toUpperCase() === 'MAIN') {
          if (item.classList.contains('reply-img-wrapper')) {
            temp = temp.trim();
            if (temp) {
              ret.push({
                type: 'text',
                content: temp
              });
              temp = '';
            }
            ret.push(...this.computed(item));
          }
        }
      } else if (nodeType === 3) {
        temp += item.nodeValue;
      }
    }
    temp = temp.trim();
    if (temp) {
      ret.push({
        type: 'text',
        content: temp
      });
      temp = '';
    }
    return ret;
  };

  getNextMsgId = () => {
    const { sessionList, client } = this.props;
    const sessionItem = sessionList.find(item => item.client === client);
    if (!sessionItem || !sessionItem.messages || !sessionItem.messages.length) {
      return 1;
    }
    const messages = sessionItem.messages;
    return messages[messages.length - 1]['id'] + 1;
  };

  sendOneText(content, isIMSend = true) {
    if (content.length > 1000) {
      throw '发送消息的字数不能超过1000个';
    }
    const { sessionList, client, onChange, IM } = this.props;
    const newSessionList = [...sessionList];
    const idx = newSessionList.findIndex(item => item.client === client);
    const sessionItem = { ...newSessionList.splice(idx, 1)[0] };
    const messages = sessionItem.messages ? [...sessionItem.messages] : [];
    const id = this.getNextMsgId();
    messages.push({
      c: content,
      u: '',
      t: moment().unix() * 1000,
      ct: 'txt',
      // 用来判断这个文本是否已经发送成功，成功后就会delete掉
      for_id: id,
      id
    });
    if (isIMSend) {
      IM.sendText(client, content, id);
    }
    const len = messages.length;
    sessionItem.last_content = messages[len - 1].c;
    sessionItem.last_update = messages[len - 1].t;
    sessionItem.messages = messages;
    newSessionList.splice(idx, 0, sessionItem);
    onChange({ session_list: newSessionList }, this.scrollToTargetPosition);
  }

  onQuickEntryChange = (content) => {
    this.sendOneText(content, false);
  }

  sendOneImage(content) {
    const { client, IM } = this.props;
    const id = this.getNextMsgId();
    const urls = [content.replace('http://', 'https://')];
    const c = {
      urls
    };
    IM.sendText(client, JSON.stringify(c), id, 11);
    const query = {
      c,
      u: '',
      t: moment().unix() * 1000,
      ct: 'img',
      id
    };
    this.handleFileUploadComplete(query);
  }

  // 发送会话信息
  handleSendMessage = () => {
    const content = this.computed(this.chatEditor);
    if (isEmpty(content)) {
      return;
    }
    const queue = new Queue();
    const { client, IM, sessionList } = this.props;
    let ifQuickRelayWhitImage = false;
    let ifQuickRelay = false;

    try {
      for (let i = 0; i < content.length; i++) {
        let c = content[i];
        if (c.type === 'text') {
          queue.task(200, () => {
            this.sendOneText(c.content);
          });
          if (
            this.candidateReplies.some(item => {
              return item.content.some(el => el.text === c.content);
            })
          ) {
            ifQuickRelay = true;
          }
        }
        if (c.type === 'img') {
          queue.task(200, () => {
            this.sendOneImage(c.content);
          });
          ifQuickRelayWhitImage = true;
          ifQuickRelay = true;
        }
        queue.start();
      }
      this.chatEditor.innerHTML = '';

      const uid = String(IM.getLoginInfo().uid);

      const sessionItem = sessionList?.find(item => item.client === client);
      api.postReply({
        sender: '2',
        sender_id: uid,
        quick_reply: ifQuickRelay ? '1' : '0',
        contains_pictures: ifQuickRelayWhitImage ? '1' : '0',
        receiver_id: client,
      }, sessionItem?.messages, client);

      api.postActiveSession({
        sender: '2',
        sender_id: uid
      });

      const {
        state: { closeSession },
        dispatch
      } = this.context;
      if (~Object.keys(closeSession).indexOf(client)) {
        dispatch({
          type: 'REMOVE_CLOSE_SESSION',
          payload: client
        });
      }

      this.setState({ replies: [] });
    } catch (error) {
      message.warning(error);
    }
  };

  handleCopyText = text => {
    const range = window.getSelection();
    if (!this.lastEditRange) {
      this.lastEditRange = range.getRangeAt(0);
    }

    const { childNodes } = this.chatEditor;
    const {
      endOffset,
      startOffset,
      // 光标结束位置所在节点， 下同
      // 1: 如果光标是卡在编辑文本中，那么commonAncestorContainer就是 this.chatEditor 子文本节点
      // 2: 如果光标不是卡在编辑文本中，那么commonAncestorContainer就是 this.chatEditor 这个节点
      endContainer,
      // 光标开始位置所在节点
      startContainer
    } = this.lastEditRange;

    let startIndex;
    let endIndex;
    let leftElement = '';
    let rightElement = '';

    if (
      endContainer.nodeValue === startContainer.nodeValue &&
      startContainer.nodeType === 3
    ) {
      if (endOffset === startOffset) {
        const domElement = document.createTextNode(text);
        this.handleCursorPositionInsertElement(domElement);
        return;
      }

      // 计算出光标左边所在的文本是第几个childNodes
      startIndex = [].findIndex.call(childNodes, function(item) {
        return item.nodeValue === startContainer.nodeValue;
      });
      leftElement = startContainer.nodeValue.slice(0, startOffset);
      rightElement = endContainer.nodeValue.slice(endOffset);
      const insertValue = document.createTextNode(
        leftElement + text + rightElement
      );

      this.chatEditor.removeChild(this.chatEditor.childNodes[startIndex]);
      this.chatEditor.insertBefore(
        insertValue,
        this.chatEditor.childNodes[startIndex]
      );
      this.lastEditRange = range.getRangeAt(0);
    } else {
      if (endOffset === startOffset) {
        const domElement = document.createTextNode(text);
        this.handleCursorPositionInsertElement(domElement);
        return;
      }

      if (startContainer.nodeType === 1) {
        startIndex = startOffset;
      } else if (startContainer.nodeType === 3) {
        // 计算出光标左边所在的文本是第几个childNodes
        startIndex = [].findIndex.call(childNodes, function(item) {
          return item.nodeValue === startContainer.nodeValue;
        });
        leftElement = startContainer.nodeValue.slice(0, startOffset);
      }

      if (endContainer.nodeType === 1) {
        endIndex = endOffset - 1;
      } else if (endContainer.nodeType === 3) {
        // 计算出光标右边所在的文本是第几个childNodes
        endIndex = [].findIndex.call(childNodes, function(item) {
          return item.nodeValue === endContainer.nodeValue;
        });
        rightElement = endContainer.nodeValue.slice(endOffset);
      }
      const insertData = document.createTextNode(
        leftElement + text + rightElement
      );

      for (let i = endIndex; i >= startIndex; i--) {
        this.chatEditor.removeChild(this.chatEditor.childNodes[i]);
      }
      this.chatEditor.insertBefore(
        insertData,
        this.chatEditor.childNodes[startIndex]
      );

      this.lastEditRange = range.getRangeAt(0);
    }
  };

  handlePaste = e => {
    e.persist();
    e.preventDefault();
    const items = [...e.clipboardData.items];
    try {
      const imgItem = items.find(item => item.type.indexOf('image') !== -1);
      if (imgItem) {
        this.file = imgItem.getAsFile();
        this.preview(this.file);
      } else {
        const textItem = items.find(item => item.type === 'text/plain');
        if (textItem) {
          textItem.getAsString(str => {
            this.handleCopyText(str);
          });
        }
      }
    } catch (error) {}
  };

  handleDrop = e => {
    e.persist();
    e.preventDefault();

    const files = e.dataTransfer.files;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (~file.type.indexOf('image')) {
        this.file = file;
        this.preview(this.file);
        break;
      }
    }
  };

  handleSendImage = () => {
    const { client, IM } = this.props;
    const id = this.getNextMsgId();

    const file = this.file;
    const { type, size, name } = file;
    if (regExpObj.picType.test(type)) {
      if (size > LIMIT_FILE_SIZE.val) {
        message.warning(LIMIT_FILE_SIZE.msg);
        return;
      }
    } else {
      message.warning('暂时还不支持非图片格式文件上传');
      return;
    }
    this.setState({
      imageModalVisible: false
    });
    return new Promise(r => {
      IM.sendImage(client, file, id, (file, urls, id) => {
        const query = {
          c: {
            name,
            urls
          },
          u: '',
          t: moment().unix() * 1000,
          ct: 'img',
          id
        };
        this.handleFileUploadComplete(query);
        r();
      });
    });
  };

  preview = file => {
    const reader = new FileReader();
    const maxsize = 200 * 1024;
    const self = this;
    reader.onload = function() {
      const result = this.result;
      let img = new Image();

      if (result.length <= maxsize) {
        self.setState({
          imageModalVisible: true,
          imageSource: result
        });
        return;
      }

      img.onload = function() {
        const compressedDataUrl = compressImage(img, file.type, maxsize / 1024);
        self.setState({
          imageModalVisible: true,
          imageSource: compressedDataUrl
        });
        img = null;
      };

      img.src = result;
    };

    reader.readAsDataURL(file);
  };

  handleSendImageCancel = () => {
    this.setState({
      imageModalVisible: false
    });
  };

  // 点击
  handleClick = () => {
    const range = window.getSelection();
    this.lastEditRange = range.getRangeAt(0);

    const lastChild = this.chatEditor.lastChild;

    if (
      !lastChild ||
      lastChild.nodeType !== 3 ||
      !lastChild.nodeValue.trim().length
    ) {
      this.setState({ replies: [] });
      return;
    }

    const content = lastChild.nodeValue.trim();
    const replies = this.candidateReplies.filter(item => {
      return item.content.some(el => {
        if (el.text) {
          return el.text.indexOf(content) !== -1 && el.text !== content;
        }
        return el.desc && el.desc.indexOf(content) !== -1;
      });
    });
    this.replyQuery = content;
    this.setState({ replies });
  };

  // 滚动到顶部拉去会话历史记录
  handleScroll = () => {
    if (!this.position) {
      this.position = this.chatRoom.scrollTop;
      return;
    }

    const delta = this.position - this.chatRoom.scrollTop;
    if (
      delta > 0 &&
      this.chatRoom.scrollTop === 0 &&
      this.isEndHistorySessionRecord
    ) {
      this.handleHistorySessionRecord();
      return;
    }
    this.position = this.chatRoom.scrollTop;
  };

  // 输入回车键 发送消息
  handleKeyUp = event => {
    // shift + entry 换行
    // entry 发送
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      this.handleSendMessage();
    }
    this.handleClick();
  };

  genReplies = () => {
    const { replies } = this.state;
    const replyQuery = this.replyQuery;
    return (
      <Menu className={styles.replies} onClick={this.selectRelay}>
        {replies.map(item => {
          return (
            <Menu.Item key={item.id}>
              {item.content.map(c => {
                if (c.text) {
                  const idx = c.text.indexOf(replyQuery);
                  if (idx !== -1) {
                    return (
                      <div className={styles.replyTxt}>
                        {c.text.substring(0, idx)}
                        <span className='text-error'>{replyQuery}</span>
                        {c.text.substring(idx + replyQuery.length)}
                      </div>
                    );
                  }
                  return (
                    <div className={styles.replyTxt} title={c.text}>
                      {c.text}
                    </div>
                  );
                }
                return (
                  <div className={styles.replyImg}>
                    <img src={c.img} title={c.desc} />
                  </div>
                );
              })}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  selectRelay = ({ item, key }) => {
    const c = this.candidateReplies.find(c => c.id == key);
    if (c) {
      this.chatEditor.innerHTML = '';
      c.content.map((cItem, i) => {
        if (cItem.text) {
          this.handleCopyTextToChatEditor(cItem.text);
          const next = c.content[i + 1];
          if (next && next.text) {
            this.inertElementToChatEditor('br');
          }
        } else {
          this.handleAddImgToChatEditor(cItem.img);
        }
      });
      this.setState({ replies: [] });
    }
  };

  showSessionTerminator = () => {
    const { dispatch } = this.context;
    dispatch({
      payload: {
        showSessionTerminator: true
      }
    });
  };

  render() {
    const {
      // 当前会话列表
      sessionList,
      client,
      // 所有会话用户的的avatar和昵称
      userInfo,
      isEndHistorySessionRecord,
      visible,
      IM
    } = this.props;
    const {
      ifChatRoomShow,
      imageModalVisible,
      imageSource,
      replies,
      searchMsgClient,
    } = this.state;
    const {
      state: { keepSession, closeSession }
    } = this.context;
    const sessionDisabled = keepSession.indexOf(client) !== -1;
    const nextMsgId = this.getNextMsgId();
    return (
      <Fragment>
        <MediaQuery>
          {isMobile => {
            if (!isMobile) {
              return (
                <Layout className={`${visible ? '' : styles.hide}`}>
                  <Sider className={styles['session-list']} width={250}>
                    <SessionList
                      userInfo={userInfo}
                      sessionList={sessionList}
                      onOpenSession={this.handleOpenSession}
                      client={client}
                      IM={IM}
                      visible={visible}
                    />
                  </Sider>
                  {!!client && (
                    <Layout style={{ padding: 6 }}>
                      <Header
                        className={classnames({
                          [styles['session-list-content']]: true,
                          [styles['im-header']]: true
                        })}>
                        <div
                          className={styles['session-list-content-username']}>
                          {(!isEmpty(userInfo) &&
                            userInfo[client] &&
                            userInfo[client].nickname) ||
                            client}
                        </div>
                        {/* <Evaluation uid={client} /> */}
                        <SessionTransfer uid={client} />
                        <div
                          className={styles['session-terminator']}
                          onClick={this.showSessionTerminator}>
                          <AIcon type='logout' />
                          <span>关闭</span>
                        </div>
                        <KeepSession uid={client} />
                      </Header>
                      <Layout>
                        <Layout style={{ position: 'relative' }}>
                          {
                            !!searchMsgClient && <div className={styles['wating-search-message-loading']}>
                              <Icon
                                type='loading1'
                                style={{ fontSize: '30px', color: '#1890ff' }}
                                spin
                              />
                            </div>
                          }
                          <div
                            className={styles['chat-content']}
                            ref={ref => (this.chatRoom = ref)}
                            onScroll={this.handleScroll}>
                            <div
                              style={{
                                width: '100%',
                                lineHeight: '30px',
                                textAlign: 'center',
                                display: !isEndHistorySessionRecord
                                  ? 'block'
                                  : 'none'
                              }}>
                              <Icon
                                type='loading1'
                                style={{ fontSize: '30px', color: '#d3d8de' }}
                                spin
                              />
                            </div>
                            <div
                              style={{
                                width: '100%',
                                lineHeight: '30px',
                                textAlign: 'center',
                                color: '#d3d8de',
                                display:
                                  isEndHistorySessionRecord &&
                                  this.state.currentHistoryID <= 0
                                    ? 'block'
                                    : 'none'
                              }}>
                              没有更多历史记录了
                            </div>
                            {this.renderCurSessionMessages()}
                          </div>
                          <div className={styles['chat-footer']}>
                            <div className={styles['chat-ops']}>
                              <ChatEmoji
                                onClick={this.handleAddEmojiToChatEditor}
                                style={{
                                  fontSize: '22px',
                                  marginRight: '20px'
                                }}
                              />
                              <UploadFile
                                onUploadComplete={this.handleFileUploadComplete}
                                IM={IM}
                                client={client}
                                nextMsgId={nextMsgId}
                                disabled={sessionDisabled}
                              />
                              <QuickReply onPaste={this.selectRelay} />
                              <QuickEntry onChange={this.onQuickEntryChange} nextMsgId={nextMsgId} client={client} IM={IM} />
                              {/* <AudioRecorder
                                onUploadComplete={this.handleFileUploadComplete}
                                IM={IM}
                                client={client}
                                 nextMsgId={nextMsgId}
                              /> */}
                            </div>
                            <Dropdown
                              overlay={this.genReplies()}
                              trigger={[]}
                              visible={replies.length > 0}
                              placement='topLeft'>
                              <div className={styles['chat-editor']}>
                                <div
                                  className={classnames({
                                    [styles['chat-editor-input']]: true,
                                    chatEditor: true
                                  })}
                                  contentEditable={!sessionDisabled}
                                  ref={ref => (this.chatEditor = ref)}
                                  onPaste={this.handlePaste}
                                  onDrop={this.handleDrop}
                                  onClick={this.handleClick}
                                  onKeyUp={this.handleKeyUp}
                                />
                              </div>
                            </Dropdown>
                            <div className={styles['chat-submit']}>
                              <span className={styles['chat-submit-tip']}>
                                按enter键发送，按shift+enter键换行
                              </span>
                              <Button
                                type='primary'
                                disabled={sessionDisabled}
                                onClick={this.handleSendMessage}>
                                发送
                              </Button>
                            </div>
                          </div>
                        </Layout>
                        <Sider
                          style={{
                            background: '#fff',
                            borderLeft: '1px solid #ddd',
                            borderRadius: '0 0 6px 0'
                          }}
                          width={380}
                          trigger={null}>
                          <Tabs uid={client} />
                        </Sider>
                      </Layout>
                    </Layout>
                  )}
                </Layout>
              );
            } else {
              return (
                <Fragment>
                  <div className={`${!ifChatRoomShow ? '' : styles.hide}`}>
                    <SessionList
                      userInfo={userInfo}
                      sessionList={sessionList}
                      onOpenSession={this.handleOpenSession}
                      client={client}
                    />
                  </div>
                  <div className={`${ifChatRoomShow ? '' : styles.hide}`}>
                    {!!client && (
                      <Layout className={styles['chat-room-height']}>
                        <Header className={styles['session-list-content']}>
                          <div
                            className={styles['session-list-content-username']}>
                            {(!isEmpty(userInfo) &&
                              userInfo[client] &&
                              userInfo[client].nickname) ||
                              client}
                          </div>
                          <div
                            className={styles['session-list-content-back']}
                            onClick={this.back2SessionList}>
                            <AIcon
                              type='arrow-left'
                              style={{ fontSize: 18, marginRight: 10 }}
                            />
                            <span>返回</span>
                          </div>
                        </Header>
                        <Layout>
                          <Layout style={{ position: 'relative' }}>
                            <div
                              className={styles['chat-content']}
                              ref={ref => (this.chatRoom = ref)}
                              onScroll={this.handleScroll}>
                              <div
                                style={{
                                  width: '100%',
                                  lineHeight: '30px',
                                  textAlign: 'center',
                                  display: !isEndHistorySessionRecord
                                    ? 'block'
                                    : 'none'
                                }}>
                                <Icon
                                  type='loading1'
                                  style={{ fontSize: '30px', color: '#d3d8de' }}
                                  spin
                                />
                              </div>
                              <div
                                style={{
                                  width: '100%',
                                  lineHeight: '30px',
                                  textAlign: 'center',
                                  color: '#d3d8de',
                                  display:
                                    isEndHistorySessionRecord &&
                                    this.state.currentHistoryID <= 0
                                      ? 'block'
                                      : 'none'
                                }}>
                                没有更多历史记录了
                              </div>
                              {this.renderCurSessionMessages()}
                            </div>
                            <div className={styles['chat-footer']}>
                              <div className={styles['chat-ops']}>
                                <ChatEmoji
                                  onClick={this.handleAddEmojiToChatEditor}
                                  style={{
                                    fontSize: '22px',
                                    marginRight: '20px'
                                  }}
                                />
                                <UploadFile
                                  onUploadComplete={
                                    this.handleFileUploadComplete
                                  }
                                  IM={IM}
                                  client={client}
                                  nextMsgId={nextMsgId}
                                />
                                <QuickReply onPaste={this.selectRelay} />
                                <QuickEntry onChange={this.onQuickEntryChange} nextMsgId={nextMsgId} client={client} IM={IM} />
                                {/* <AudioRecorder
                                  onUploadComplete={
                                    this.handleFileUploadComplete
                                  }
                                  IM={IM}
                                  client={client}
                                  nextMsgId={nextMsgId}
                                /> */}
                              </div>
                              <Dropdown
                                overlay={this.genReplies()}
                                trigger={[]}
                                visible={replies.length > 0}
                                placement='topLeft'>
                                <div className={styles['chat-editor']}>
                                  <div
                                    className={classnames({
                                      [styles['chat-editor-input']]: true,
                                      chatEditor: true
                                    })}
                                    contentEditable={!keepSession}
                                    ref={ref => (this.chatEditor = ref)}
                                    onPaste={this.handlePaste}
                                    onDrop={this.handleDrop}
                                    onClick={this.handleClick}
                                    onKeyUp={this.handleKeyUp}
                                  />
                                </div>
                              </Dropdown>
                              <div className={styles['chat-submit']}>
                                <Button
                                  type='primary'
                                  onClick={this.handleSendMessage}>
                                  发送
                                </Button>
                              </div>
                            </div>
                          </Layout>
                        </Layout>
                      </Layout>
                    )}
                  </div>
                </Fragment>
              );
            }
          }}
        </MediaQuery>
        <Modal
          visible={imageModalVisible}
          title='发送图片'
          onOk={this.handleSendImage}
          onCancel={this.handleSendImageCancel}
          width={452}
          footer={[
            <Button
              key='cancel'
              size='large'
              onClick={this.handleSendImageCancel}>
              取消
            </Button>,
            <Button
              key='submit'
              type='primary'
              size='large'
              onClick={this.handleSendImage}>
              发送
            </Button>
          ]}>
          <div className={styles['sendimage']}>
            <img src={imageSource} />
          </div>
        </Modal>
        <SessionTerminator uid={client} />
      </Fragment>
    );
  }
}
