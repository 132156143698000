import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Modal, Button, Spin, message, Icon } from 'antd';
import { isEmpty } from 'lodash';
import { Context } from '@context';
import api from '@services/api';
import Draggable from '@components/Draggable';
import styles from './index.less';
import defaultAvator from '../../assets/images/avatar.png';

const SessionTransfer = ({ uid }) => {
  const { state, dispatch } = useContext(Context);
  const { IM } = state;
  const [modalVisible, setModalVisible] = useState(false);
  const [transferId, setTransferId] = useState(null);
  const [candidateTransfer, setCandidateTransfer] = useState([]);

  useEffect(() => {
    const fn = async (status, data) => {
      if (status !== 'FAIL') {
        if (!isEmpty(data)) {
          const { data: candidateTransfer } = await api.getCsInfo({
            app_uid: data
          });
          setCandidateTransfer(candidateTransfer);
        } else {
          message.warning('当前没有其他在线客服人员');
        }
      } else {
        message.warning('获取失败，请稍后重试');
      }
    };
    IM.on('session:colleagues:reply', fn);
    return () => IM.off('session:colleagues:reply', fn);
  }, []);

  const init = useCallback(() => {
    IM.onColleagues();
    setModalVisible(true);
  }, []);

  const onCancel = useCallback(() => {
    setTransferId(null);
    setModalVisible(false);
  }, []);

  const transfer = useCallback(() => {
    if (!transferId) {
      message.error('请选择转接的客服');
      return;
    }
    IM.onSwitch(uid, transferId);
    dispatch({
      type: 'REMOVE_CLOSE_SESSION',
      payload: uid
    });
    onCancel();
  }, [transferId, uid]);

  const wrapClassName = 'session-transfer-wrap';

  const title = (
    <Draggable
      visible={modalVisible}
      title='选择转接客服'
      wrapClassName={wrapClassName}
    />
  );
  return (
    <>
      <div className={styles['session-transfer']} onClick={init}>
        <Icon type='sync' />
        <span>转接</span>
      </div>
      {isEmpty(candidateTransfer) ? null : (
        <Modal
          visible={modalVisible}
          title={title}
          width={600}
          onCancel={onCancel}
          bodyStyle={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}
          mask={false}
          maskClosable={false}
          wrapClassName={wrapClassName}
          afterClose={() => {
            document.querySelector(`.${wrapClassName}`).style.transform =
              'translate(0px, 0px)';
          }}
          footer={[
            <Button key='cancel' onClick={onCancel}>
              取消
            </Button>,
            <Button key='submit' type='primary' onClick={transfer}>
              转接
            </Button>
          ]}>
          {!isEmpty(candidateTransfer) ? (
            <div className={styles['transfer-list']}>
              {candidateTransfer.map(({ app_uid, email, nick }) => (
                <div
                  key={app_uid}
                  className={styles['transfer-item']}
                  style={{
                    background: transferId === app_uid ? '#c6e5fe' : '#fff'
                  }}
                  onClick={() => setTransferId(app_uid)}>
                  <img
                    src={defaultAvator}
                    alt='客服头像'
                    className={styles['transfer-avator']}
                  />
                  <span className={styles['transfer-name']}>
                    {nick || email || app_uid}
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <Spin />
          )}
        </Modal>
      )}
    </>
  );
};

export default React.memo(SessionTransfer);
