import React from 'react';
import ReactDOM from 'react-dom';
import { unregister } from './registerServiceWorker';
import { ConfigProvider } from 'antd';
import { ContextProvider } from '@context';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import './momentCustom';
import App from './App';

ReactDOM.render(
  <ConfigProvider locale={zh_CN}>
    <ContextProvider>
      <App />
    </ContextProvider>
  </ConfigProvider>,
  document.getElementById('root')
);
unregister();
