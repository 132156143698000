import { isEmpty } from 'lodash';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import { AUTH_JWT_TOKEN_NAME } from '@constant';
import { getStore } from '@utils/storage';

// 正则验证规则
export const regExpObj = {
  picType: /^image\/(jpg|png|jpeg|bmp|gif)$/i,
  fileType: /^application\/zip$/i,
  videoType: /^video\/mp4$/i,
  audioType: /^audio\/(mp3|ogg|wav|mpeg|aac).*$/i,
  emojiType: /\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/gi
};

function genBase64(url) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onreadystatechange = function() {
      if (xhr.readyState == 4 && xhr.status == 200) {
        resolve(this.response);
      }
    };
    xhr.onerror = function() {
      reject();
    };
    xhr.open('GET', url, true);
    xhr.send(null);
  });
}

// 将base64转成blob
export const dataURLtoBlob = dataurl => {
  return new Promise((resolve, reject) => {
    const arr = dataurl.split(',');
    // 结果 image/png
    const match = arr[0].match(/:(.*?);/);
    if (match) {
      const mime = match[1];
      const bstr = atob(arr[1].replace(/\s/g, ''));
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      resolve(new Blob([u8arr], { type: mime }));
    } else {
      genBase64(dataurl).then(function(blob) {
        resolve(blob);
      });
    }
  });
};

// 读取复制的文件，并粘贴到富文本中
export const readFileAndInsertTarget = (file, target) => {
  const blob = file.getAsFile();
  const reader = new FileReader();
  reader.onload = event => {
    const img = new Image();
    img.src = event.target.result;
    target.appendChild(img);
  };
  reader.readAsDataURL(blob);
};

export const ifImageCompleted = url => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    // if (img.complete) {
    //   resolve();
    // }
    img.onload = function() {
      resolve();
    };
    img.onerror = function() {
      reject();
    };
    img.src = url;
  });
};

export const compressImage = (img, fileType, maxWidth = 200) => {
  let canvas = document.createElement('canvas');
  let ctx = canvas.getContext('2d');

  const proportion = img.width / img.height;
  const width = maxWidth;
  const height = maxWidth / proportion;

  canvas.width = width;
  canvas.height = height;

  ctx.fillStyle = '#fff';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(img, 0, 0, width, height);

  const base64data = canvas.toDataURL(fileType, 0.75);
  canvas = ctx = null;

  return base64data;
};

export const getAudioDuration = src => {
  return new Promise(function(resolve) {
    const url = URL.createObjectURL(src);
    let audio = new Audio(url);

    audio.addEventListener('durationchange', function(e) {
      const duration = e.target.duration;
      if (duration !== Infinity) {
        audio = null;
        URL.revokeObjectURL(url);
        resolve(duration);
      }
    });
    audio.load();
    setTimeout(() => resolve(0), 3000);
  });
};

export function eraseEmpty(obj) {
  const res = {};
  for (let key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = obj[key].trim();
    }
    if (!isEmpty(obj[key])) {
      res[key] = obj[key];
    }
  }
  return res;
}

// 转化成千分位
export function format(value) {
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const getQueryString = () => {
  const result = {};
  const queryString = window.location.search.slice(1);
  const re = /([^&=]+)=([^&]*)/g;
  let m;

  while ((m = re.exec(queryString))) {
    result[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
  }

  return result;
};

export const getLoginParams = () => {
  // 兼容之前通过url登录形式

  const params = getQueryString();
  if (params.token && params.app) {
    return params;
  }
  // 通过平台登录
  // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfaWQiOjQsImFwcF9uYW1lIjoi5a6e5oOg5Za1IiwiYXBwX3VpZCI6IjEwMDAiLCJjb21wYW55X25hbWUiOiIiLCJjb25maWdfdXJsIjoiIiwiY3NfaWQiOiJjbXMtMSIsImNzX25hbWUiOiIiLCJlbWFpbCI6Inp6ZkAxMjMuY29tIiwiZXhwaXJlIjoxNjE2NDY2Njg0LCJpZCI6MjQ3LCJsb2dvIjoiaHR0cDovL2NydWlzZXItcHVzaC5vc3MtY24tc2hhbmdoYWkuYWxpeXVuY3MuY29tL3FjaGF0L2U2OTg1ZGQ3LWVhYjEtNDBjZS04MDFlLTAzMmI2OTU5NzdmNC5wbmciLCJtYXhfc2VydmUiOjEwMCwibmljayI6Inp6ZiIsInJvbGUiOjEsInR5cGUiOjR9.Qw0YmAwM6bj9OdfbKmwx-fbtB-HGXDMor1QTz8bhwnk';
  // const JWT_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBfaWQiOjQsImFwcF9uYW1lIjoi5a6e5oOg5Za1IiwiYXBwX3VpZCI6ImNtcy0xMCIsImNvbXBhbnlfbmFtZSI6IiIsImNvbmZpZ191cmwiOiIiLCJjc19pZCI6ImNtcy0xIiwiY3NfbmFtZSI6IiIsImVtYWlsIjoienpmQDEyMy5jb20iLCJleHBpcmUiOjE2NDYyNzc4MzEsImlkIjoyNDcsImxvZ28iOiJodHRwOi8vY3J1aXNlci1wdXNoLm9zcy1jbi1zaGFuZ2hhaS5hbGl5dW5jcy5jb20vcWNoYXQvZTY5ODVkZDctZWFiMS00MGNlLTgwMWUtMDMyYjY5NTk3N2Y0LnBuZyIsIm1heF9zZXJ2ZSI6MTAwLCJuaWNrIjoienpmIiwicm9sZSI6MSwidHlwZSI6NH0.1vYHmmYSgME5k6sANShEkMpLiGUnLzh8znzN0atIGfs'; //
  const JWT_TOKEN = params.token || Cookies.get(AUTH_JWT_TOKEN_NAME);

  if (isEmpty(JWT_TOKEN)) {
    return {};
  }
  const jwt = jwtDecode(JWT_TOKEN);
  return {
    admin: true,
    token: JWT_TOKEN,
    app: jwt.app_id,
    uid: jwt.app_uid
  };
};

export const getTokenInfo = key => {
  const { token } = getLoginParams();
  return token;
};

export const isOffline = () => {
  return !!getStore('offline');
};
