import React from 'react';
import PropTypes from 'prop-types';

export default function Icon(props) {
  const {
    type, className, style, spin,
  } = props;
  const attr = { ...props };
  delete attr.type;
  delete attr.className;
  delete attr.style;
  delete attr.spin;
  return (
    <i
      className={`iconfont icon-${type} ${className} ${spin ? 'animation-spin' : ''}`}
      style={style}
      {...attr}
    />
  );
}

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  style: PropTypes.object,
  spin: PropTypes.bool,
};

Icon.defaultProps = {
  className: '',
  style: null,
  spin: false,
};
