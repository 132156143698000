import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Input, Button, DatePicker } from 'antd';
import { noop } from 'lodash';
import { eraseEmpty } from '../../utils';

const { RangePicker } = DatePicker;

@Form.create()
export default class Search extends Component {
  static propTypes = {
    onSubmit: PropTypes.func
  };

  static defaultProps = {
    onSubmit: noop
  };

  state = {};

  getFields() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Fragment>
        <Col span={6}>
          <Form.Item label={'用户ID:'}>
            {getFieldDecorator('enc_uid', {})(<Input placeholder='用户ID' />)}
          </Form.Item>
        </Col>
        {/* <Col span={5}>
          <Form.Item label={'订单号:'}>
            {getFieldDecorator('ordernum', {})(<Input placeholder='订单号' />)}
          </Form.Item>
        </Col> */}
        <Col span={6}>
          <Form.Item label={'消息内容:'}>
            {getFieldDecorator('content', {})(<Input placeholder='消息内容' />)}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={'时间范围:'}>
            {getFieldDecorator('time_range', {})(<RangePicker />)}
          </Form.Item>
        </Col>
      </Fragment>
    );
  }

  handleSearch = e => {
    e.preventDefault();
    const { form, onSubmit } = this.props;
    form.validateFields((err, values) => {
      const time_range = values['time_range'];
      const submitValues = {
        ...values
      };
      if (time_range && time_range.length !== 0) {
        submitValues.time_range = `${time_range[0].valueOf()}000000,${time_range[1].valueOf()}000000`;
      }
      onSubmit(eraseEmpty(submitValues));
    });
  };

  handleReset = () => {
    this.props.form.resetFields();
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 }
      }
    };
    return (
      <Form onSubmit={this.handleSearch} {...formItemLayout}>
        <Row gutter={24}>
          {this.getFields()}
          <Col span={6} style={{ textAlign: 'right', paddingRight: '20px' }}>
            <Button type='primary' htmlType='submit'>
              搜索
            </Button>
            <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
              清空
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}
