import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../../Icon';
import styles from './index.less';
import defaultImage from '../../../assets/images/defaultImage.png';

export default class Image extends PureComponent {
  static propTypes = {
    content: PropTypes.object,
    done: PropTypes.bool,
    onClickImage: PropTypes.func
  };

  static defaultProps = {
    content: {},
    done: true,
    onClickImage: () => {}
  };

  constructor() {
    super();
    this.state = {
      // 是否展示消息图片
      visible: false
    };
  }

  // image对象的onload事件成功触发
  handleLoaded = () => this.setState({ visible: true });

  render() {
    const { content, done = true, onClickImage } = this.props;
    const { visible } = this.state;
    let thumbnail, preview;
    if (content.url) {
      thumbnail = content.url;
      preview = content.url;
    } else {
      thumbnail = content.urls[content.urls.length - 1];
      preview = content.urls[0];
    }
    return (
      <div className={styles.image}>
        {done && (
          <Fragment>
            <img
              ref={ref => (this.image = ref)}
              src={defaultImage}
              alt='tupian'
              className={classnames({
                [styles.img]: true,
                [styles.hide]: visible
              })}
            />
            <img
              src={thumbnail}
              alt='tupian'
              className={classnames({
                [styles.img]: true,
                [styles.hide]: !visible
              })}
              onClick={() => onClickImage(preview)}
              onLoad={this.handleLoaded}
            />
          </Fragment>
        )}
        <div
          className={classnames({
            [styles.loading]: true,
            [styles.hide]: !!done
          })}>
          <Icon type='loading1' style={{ fontSize: 30, color: '#ccc' }} spin />
        </div>
      </div>
    );
  }
}
