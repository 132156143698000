import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import moment from 'moment';
import avatar from '../../assets/images/avatar.png';
import ImageComp from './Image';

const SearchItem = ({ client, item, onOpenSession, keyword }) => {
	const { msg_hits } = item;
	// 多条消息内容取第一条
	const [firstItem] = msg_hits;

	const onItemClick = useCallback(() => {
		if (onOpenSession) {
			onOpenSession(item.from_uid, firstItem.msg_id);
		}
	}, [onOpenSession, item]);

	const contentHtml = useMemo(() => {
		const reg = new RegExp(`${keyword}`, 'ig');
		const index = firstItem.content.indexOf(keyword);
		const str = index > 7 ? `...${firstItem.content.substr(index - 2)}` : firstItem.content;
    return `<span>${str.replace(reg, '<font color="red">$&</font>')}</span>`;
	}, [keyword]);

  return (
    <div className={"sessionlist-list"} onClick={onItemClick}>
      <div
        className={`sessionlist-list-avatar ${
          client === item.from_uid ? "active" : ""
        }`}
      >
        <ImageComp
					src={avatar}
					alt='avatr'
					className='sessionlist-list-avatar-img'
				/>
      </div>
      <div className='sessionlist-list-middle sessionlist-list-middle-search'>
				<p className='sessionlist-list-middle-name'>
					{item.from_uid}
				</p>
				<p className='sessionlist-list-middle-session' dangerouslySetInnerHTML={{
					__html: contentHtml
				}} />
      </div>
			<div className='sessionlist-list-time'>
				{item.created &&
					moment(+item.created.toString().slice(0, 13)).format(
						'HH:mm'
					)}
			</div>
    </div>
  );
};

SearchItem.propTypes = {
  client: PropTypes.string,
  item: PropTypes.object,
  onOpenSession: PropTypes.func,
  keyword: PropTypes.string,
};

export default SearchItem;
