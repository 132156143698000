import React, { useState, useContext, useEffect } from 'react';
import { Popover, Menu, Icon } from 'antd';
import { isEmpty } from 'lodash';
import { Context } from '@context';
import api from '@services/api';
import { dserialize } from '@utils/serialize';

import styles from './index.less';
import QuickIcon from '../../assets/svg/qr.svg';

const QuickReply = ({ onPaste }) => {
  const {
    state: { IM }
  } = useContext(Context);
  const [menu, setMenu] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const query = async () => {
      let { data } = await api.queryReplies({
        cs_id: IM.getLoginInfo().csid
      });
      data = (data || []).map(d => {
        d.content = dserialize(d.content);
        return d;
      });

      if (!isEmpty(data)) {
        const m = (
          <Menu className={styles.replies} onClick={onClick}>
            {data.map(item => {
              return (
                <Menu.Item key={item.id}>
                  {item.content.map((c, index) => {
                    if (c.text)
                      return (
                        <div key={`reply_c_${index}`} className={styles.replyTxt} title={c.text}>
                          {c.text}
                        </div>
                      );
                    return (
                      <div key={`reply_c_${index}`} className={styles.replyImg}>
                        <img src={c.img} alt="" title={c.desc} />
                      </div>
                    );
                  })}
                </Menu.Item>
              );
            })}
          </Menu>
        );
        setMenu(m);
      }
    };
    query();
  }, []);

  const onClick = args => {
    onPaste(args);
    setVisible(false);
  };

  const handleVisibleChange = visible => {
    setVisible(visible);
  };

  return (
    <Popover
      content={menu}
      trigger='click'
      placement='topLeft'
      visible={visible}
      onVisibleChange={handleVisibleChange}>
      <span className={styles['quick-icon']}>
        <Icon
          component={QuickIcon}
          style={{ fontSize: '35px', marginLeft: '-8px', marginTop: '-2px' }}
        />
      </span>
    </Popover>
  );
};

export default React.memo(QuickReply);
