import React, { useCallback, useState, useContext } from "react";
import { Menu, Popover, Icon } from "antd";
import { Context } from '@context';
import styles from './index.less';

const MenuComponent = ({ setVisible, client, nextMsgId, onChange }) => {
  const {
    state: { IM }
  } = useContext(Context);

  const onClick = useCallback((e) => {
    setVisible(false);
    switch (e.key) {
      case '1': {
        // 发送消息让用户填写手机号码
        const message = '[系统: 填写手机号]';
        IM.sendOutputPhone(client, message, nextMsgId);
        onChange(message);
        break;
      }
      case '2': {
        // 发送退费挽留窗口给用户
        const message = '[系统: 退费窗口]';
        IM.sendSaveUserRefund(client, message, nextMsgId);
        onChange(message);
        break;
      }
      case '3': {
        // 发送退费挽留窗口给用户
        const message = '[系统: 话费礼包退费链接]';
        IM.sendCustomMessage(client, message, nextMsgId, {
          fallback: message,
          refundPhoneBill: true,
        });
        onChange(message);
        break;
      }
      case '4': {
        // 发送退费挽留窗口给用户
        const message = '[系统: 外卖礼包退费链接]';
        IM.sendCustomMessage(client, message, nextMsgId, {
          fallback: message,
          takeoutGiftPackage: true,
        });
        onChange(message);
        break;
      }
      case '5': {
        // 发送退费挽留窗口给用户
        const message = '[系统: 电费礼包退费链接]';
        IM.sendCustomMessage(client, message, nextMsgId, {
          fallback: message,
          electricityBillGiftPackage: true,
        });
        onChange(message);
        break;
      }
      default:
        break;
    }
  }, [setVisible, nextMsgId, client]);
  return (
    <Menu className={styles.quickEntry} onClick={onClick}>
      <Menu.Item className={styles.menuItem} key="1">发送手机号填写入口</Menu.Item>
      <Menu.Item className={styles.menuItem} key="2">发送退费挽留入口</Menu.Item>
      <Menu.Item className={styles.menuItem} key="3">发送话费礼包退费链接</Menu.Item>
      <Menu.Item className={styles.menuItem} key="4">发送外卖礼包退费链接</Menu.Item>
      <Menu.Item className={styles.menuItem} key="5">发送电费礼包退费链接</Menu.Item>
    </Menu>
  );
};

export const QuickEntry = ({ client, IM, nextMsgId, onChange }) => {
  const [visible, setVisible] = useState(false);
  const handleVisibleChange = useCallback((visible) => {
    setVisible(visible);
  }, []);

  return (
    <Popover
      overlayClassName={styles.quickEntryPopover}
      content={<MenuComponent onChange={onChange} nextMsgId={nextMsgId} client={client} IM={IM} setVisible={setVisible} />}
      trigger="click"
      placement="topLeft"
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <span className={styles.quickEntryIcon}>
        <Icon type='link' />
      </span>
    </Popover>
  );
};
