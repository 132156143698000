export EMOJI from './emoji';

// 推送的消息类型
export const MESSAGETYPE = [
  { type: 0, content: '', ctype: 'txt' },
  { type: 1, content: '', ctype: 'system' },
  { type: 2, content: '[图片]', ctype: 'img' },
  { type: 3, content: '[语音]', ctype: 'radio' },
  { type: 4, content: '[视频]', ctype: 'video' },
  { type: 5, content: '[商品]', ctype: 'goods' },
  { type: 6, content: '[订单]', ctype: 'order' },
  { type: 7, content: '[活动]', ctype: 'active' },
  { type: 8, content: '[邀请评价]', ctype: 'evaluate' },
  { type: 11, content: '[图片]', ctype: 'img' },
  { type: 12, content: '[系统: 退费窗口]', ctype: 'txt' },
  { type: 13, content: '', ctype: 'txt' },
  { type: 14, content: '', ctype: 'txt' },
  { type: 15, content: '[系统: 填写手机号]', ctype: 'txt' },
];

// 会话类型，会话列表
export const SESSION_LIST = '1';
// 会话类型，当前待接入会话列表
export const SESSION_WAITING_LIST = '2';
// 会话类型，历史会话列表
export const SESSION_HISTORY_LIST = '3';
// 会话类型，会话设置
export const SESSION_SETTING = '4';

// 当前会话状态
export const SESSIONSTATUS = [
  {
    code: '0',
    status: '在线',
    color: '#52C41A'
  },
  {
    code: '1',
    status: '挂起',
    color: '#f5222d'
  },
  {
    code: '2',
    status: '离线',
    color: '#BFBFBF'
  }
];

// 文件限制
export const LIMIT_FILE_SIZE = {
  val: 1024 * 1024 * 10,
  msg: '文件大于5M，无法进行上传'
};
export const LIMIT_AUDIO_DURATION = {
  val: 60,
  msg: '语音时长不能超过60秒'
};

export const STORE_NAME = 'IM_STORE_NAME';
export const AUTH_JWT_TOKEN_NAME = `imadmin${
  process.env.IM_ENV === 'prod' && process.env.NODE_ENV === 'production'
    ? 'production'
    : 'alpha'
}`;
export const DEBOUNCE_VALUE = 300;
export const POLL_INTERVAl = 1000 * 10;
export const STATE_RECALL = 8;