import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { message, Icon } from 'antd';
import { noop, isEmpty } from 'lodash';
import moment from 'moment';
import { regExpObj, getAudioDuration } from '../../utils';
import { LIMIT_FILE_SIZE, LIMIT_AUDIO_DURATION } from '../../constant';

export default class UploadFile extends PureComponent {
  static propTypes = {
    onUploadComplete: PropTypes.func,
    IM: PropTypes.object,
    client: PropTypes.string,
    disabled: PropTypes.bool,
    nextMsgId: PropTypes.number
  };

  static defaultProps = {
    onUploadComplete: noop,
    IM: null,
    client: '',
    disabled: false,
    nextMsgId: 1
  };

  getType = type => {
    let ct = '';
    if (regExpObj.picType.test(type)) {
      ct = 'img';
    } else if (regExpObj.fileType.test(type)) {
      ct = 'file';
    } else if (regExpObj.videoType.test(type)) {
      ct = 'video';
    } else if (regExpObj.audioType.test(type)) {
      ct = 'radio';
    }
    return ct;
  };

  handleChange = async event => {
    event.persist();
    const { files } = event.target;
    if (isEmpty(files)) return;
    const file = files[0];
    const { IM, client, nextMsgId } = this.props;
    const { type, size } = file;
    const ct = this.getType(type);

    if (size > LIMIT_FILE_SIZE.val) {
      message.warning(LIMIT_FILE_SIZE.msg);
      return;
    }
    if (ct === 'radio') {
      const duration = await getAudioDuration(file);
      if (duration > LIMIT_AUDIO_DURATION.val) {
        message.warning(LIMIT_AUDIO_DURATION.msg);
        return;
      }
    }
    const handlerMap = {
      img: 'sendImage',
      radio: 'sendAudio'
    };

    await IM[handlerMap[ct]](client, file, nextMsgId, this.handleCallBack);
    event.target.value = '';
  };

  // 回调函数
  handleCallBack = (file, urls, id) => {
    const { name, type } = file;
    const ct = this.getType(type);
    const query = {
      c: {
        name,
        urls
      },
      u: '',
      t: moment().unix() * 1000,
      ct,
      id
    };
    this.props.onUploadComplete(query);
  };

  render() {
    const { disabled } = this.props;
    return (
      <label>
        <Icon
          type='folder-open'
          style={{ fontSize: '19px', marginRight: '20px', marginTop: '-2px' }}
        />
        <input
          type='file'
          accept='image/png, image/jpg, image/jpeg, image/bmp, image/gif, audio/mpeg, audio/wav, audio/aac'
          onChange={this.handleChange}
          disabled={disabled}
          style={{ display: 'none' }}
        />
      </label>
    );
  }
}
