import React, { createContext, useReducer, useMemo } from 'react';
import { getIMSettingStore } from './utils/storage';
import defaultAvator from './assets/images/avatar.png';

const Context = createContext();
const initValue = {
  customerInfo: {
    logo: defaultAvator,
    nick: ''
  }, //客服信息
  csInfo: {},
  IM: null, // IM sdk相关
  menuFold: true, // 菜单折叠
  openNotify: true, // 消息通知
  keepSession: [], // 保持会话
  closeSession: {}, //自动关闭
  totalUnread: 0, // 未读数
  showSessionTerminator: false, // 关闭会话
  ...getIMSettingStore()
};

function reducer(state, action) {
  switch (action.type) {
    case 'UPDATE_SYSTEM_INFO':
      return { ...state, ...action.payload };
    case 'ADD_KEEP_SESSION':
      return {
        ...state,
        keepSession: [...state.keepSession, action.payload]
      };
    case 'REMOVE_KEEP_SESSION':
      return {
        ...state,
        keepSession: state.keepSession.filter(item => item !== action.payload)
      };
    case 'ADD_CLOSE_SESSION':
      return {
        ...state,
        closeSession: { ...state.closeSession, ...action.payload }
      };
    case 'REMOVE_CLOSE_SESSION':
      delete state.closeSession[action.payload];
      return {
        ...state,
        closeSession: { ...state.closeSession }
      };
    default:
      return { ...state, ...action.payload };
  }
}

const ContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initValue);
  const value = useMemo(() => {
    return {
      state,
      dispatch
    };
  }, [state]);
  return (
    <Context.Provider value={value} {...props}>
      {props.children}
    </Context.Provider>
  );
};

const ContextConsumer = Context.Consumer;

export { Context, ContextProvider, ContextConsumer };
