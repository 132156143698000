import * as Sentry from '@sentry/browser';
import packageJson from '../../../../package.json';
import { setConfig, HTML5Storage, SHMAPICollector, setContextParams, dispatchEvent, setLogConfig, SHMLogCollector } from '@shm/interface/lib/index.app.web';

const isProd = process.env.NODE_ENV === 'production' && process.env.IM_ENV !== 'test';
const config = {
  IS_LOCAL_DEV: !isProd,
  SENTRY_DSN: isProd ? 'https://0304f6c03b4f4c19871817ac3976b6bf@sentry.shmer.net/7' : 'https://0304f6c03b4f4c19871817ac3976b6bf@sentry.shmer.net/7',
  SENTRY_ENV: isProd ? 'Production' : 'Staging',
}

// Sentry init
Sentry.init({
  enabled: !config.IS_LOCAL_DEV,
  dsn: config.SENTRY_DSN,
  environment: config.SENTRY_ENV,
  release: packageJson.version,
  tracesSampleRate: 1,
  integrations: (integrations) =>
      integrations
          .filter((item) => item.name !== 'GlobalHandlers' && item.name !== 'TryCatch')
          .concat(
              // disable unhandled rejection, error report
              new Sentry.Integrations.GlobalHandlers({
                  onerror: true,
                  onunhandledrejection: false,
              }),
              new Sentry.Integrations.TryCatch({
                  requestAnimationFrame: false,
              }),
          ),
});
dispatchEvent('sentryInited');

// config log behavior according to config
setLogConfig({
  console: config.IS_LOCAL_DEV,
  consoleFilter: {
      category: {
          excludes: ['api', 'event'],
      },
  },
  sentry: !config.IS_LOCAL_DEV,
  sentryFilter: {
      level: 'warn',
  },
});

const collectors = [new SHMAPICollector()];
if (config.IS_LOCAL_DEV) {
  collectors.push(new SHMLogCollector());
}

export const initReport = user_id => {
  const apiServer =
    process.env.NODE_ENV === 'production' && process.env.IM_ENV !== 'test'
      ? 'https://api.shmiao.net'
      : 'https://betaapi.shmiao.net';

  if (user_id.startsWith('cms-')) {
    user_id = user_id.substring(4);
  }
  user_id = parseInt(user_id);
  setConfig({
    apiServer,
    user_id,
    platform: 'web',
    storage: new HTML5Storage(),
    log: config.IS_LOCAL_DEV,
    report: {
        collectors,
        // disable batch report if we are using rpc collector, app would put them into batch
        maxReportEvents: 20,
    },
  });
  setContextParams({
    mid: user_id,
    user_agent: window.navigator.userAgent,
    path: window.location.pathname.slice(1) + window.location.search,
  });
};
