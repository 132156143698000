import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Layout, Table, Button, Modal } from 'antd';
import { noop, isEmpty } from 'lodash';
import uuid from 'uuid';
import Icon from '@components/Icon';
import ChatRoom from '@components/ChatRoom';
import api from '@services/api';
import { MESSAGETYPE, EMOJI } from '@constant';
import Search from './Search';
import columns from './constant';
import styles from './index.less';
import { regExpObj } from '@utils';
import { Context } from '@context';
import { STATE_RECALL } from '@constant';

const { emojiType } = regExpObj;

export default class SessionRecord extends PureComponent {
  static contextType = Context;

  static propTypes = {
    IM: PropTypes.object,
    visible: PropTypes.bool,
    logo: PropTypes.string,
    onChange: PropTypes.func
  };

  static defaultProps = {
    IM: {},
    visible: false,
    logo: '',
    onChange: noop
  };

  constructor(props) {
    super(props);
    const column = [...columns];
    const operating = column.find(item => item.key === 'operating');
    operating.render = (text, record) => (
      <Icon
        type='Group'
        className='session-record-huibo'
        onClick={() => this.handleShowModal(record)}>
        &nbsp;回拨
      </Icon>
    );

    this.state = {
      column,
      // 当前页码
      pageNum: 1,
      // 每页展示多少条数据
      pageSize: 20,
      // 最大页
      maxPage: Infinity,
      // 模态框是否显示
      visibleModal: false,
      // 当前被选中的用户信息
      currentSelectedUser: {},
      // 会话列表
      sessionList: [],
      fetchHist: false,
      // 当前msg_id
      currentHistoryMsgId: 0,
      // 获取历史记录的状态 END 表示完成 LOADING 表示进行中
      // 因为获取历史记录是异步的，所以在实例属性中也添加了一个 getHistoryRecordStatus
      getHistoryRecordStatus: 'END',
      dataSource: []
    };
    // 获取历史记录的状态 true表示完成 false表示请求中
    this.getHistoryRecordStatus = 'END';
    this.since = [0];
    this.queryParams = {};
    this.sessionRecordBoxRef = React.createRef();
    this.timer = null;
  }

  componentDidMount() {
    this.queryMsg();
  }

  componentWillUnmount() {
    this.timer && clearTimeout(this.timer);
  }

  queryMsg = async () => {
    const { IM } = this.props;
    const { pageNum, pageSize } = this.state;

    let queryParams = null;
    let isNoCond = false;

    if (isEmpty(this.queryParams)) {
      isNoCond = true;
      queryParams = this.since[pageNum - 1];
    } else {
      queryParams = {
        ...this.queryParams,
        ...{
          count: pageSize,
          offset: (pageNum - 1) * pageSize
        }
      };
    }

    try {
      const threads = await IM[isNoCond ? 'queryMsgWhenNoCond' : 'queryMsg'](
        queryParams
      );
      this.handleGetThreadList(threads, isNoCond);
    } catch (e) {
      this.handleGetThreadList([]);
    }
  };

  // 获取历史记录
  handleGetThreadList = async (threads, isNoCond = false) => {
    const { pageNum, pageSize } = this.state;
    threads = threads || [];

    if (threads.length < pageSize) {
      this.setState({ maxPage: pageNum });
    } else {
      this.setState({ maxPage: pageNum + 1 });
    }
    if (isEmpty(threads)) {
      if (pageNum >= 2) {
        this.setState({ pageNum: pageNum - 1, maxPage: pageNum - 1 });
      }
      this.setState({ dataSource: [] });
      return;
    }

    const uids = [];
    const dataSource = [];

    if (isNoCond) {
      threads.forEach(item => {
        let recallText = '';
        if (item.snap_msg.state === STATE_RECALL) {
          recallText = '[撤回]';
        }

        dataSource.push({
          last_update: item.last_update,
          last_content:
            recallText ||
            MESSAGETYPE.find(it => it.type === item.snap_msg.type)?.content ||
            item.snap_msg.content?.replace(emojiType, match => {
              const ifMatch = EMOJI.find(emoji => emoji.name === match);
              if (ifMatch) {
                return ifMatch.text;
              }
              return match;
            }),
          uid: item.peer.uid,
          msg_id: item.snap_msg.msg_id,
          id: uuid.v4()
        });
      });
      this.since.push(threads[threads.length - 1].last_update);
    } else {
      threads.forEach((item, index) => {
        uids.push(item.client_uid_enc);
        dataSource.push({
          last_update: item.created,
          last_content: item.content,
          uid: item.client_uid_enc,
          msg_id: item.msg_id,
          id: uuid.v4()
        });
      });
    }

    // const userInfo = await this.getUserInfo(uids);
    // dataSource.forEach(item => {
    //   item.avatar = userInfo[item.uid].avatar;
    //   item.nickname = userInfo[item.uid].nickname;
    // });
    this.setState({ dataSource });
  };

  getUserInfo = async uids => {
    const { payload } = await api.getUserInfo({
      uids: uids.toString()
    });
    return payload;
  };

  // 上一页
  handlePrevPage = () => {
    const { pageNum } = this.state;
    this.setState({ pageNum: pageNum - 1 }, this.queryMsg);
  };

  // 下一页
  handleNextPage = () => {
    const { pageNum } = this.state;
    this.setState({ pageNum: pageNum + 1 }, this.queryMsg);
  };

  search = values => {
    this.queryParams = values;
    this.since = [0];
    this.setState({ pageNum: 1 }, this.queryMsg);
  };

  // 滚动到顶部拉去会话历史记录
  handleScroll = () => {
    if (!this.position) {
      this.position = this.sessionRecordBoxRef.current.scrollTop;
      return;
    }
    const delta = this.position - this.sessionRecordBoxRef.current.scrollTop;
    if (
      delta > 0 &&
      this.sessionRecordBoxRef.current.scrollTop === 0 &&
      this.getHistoryRecordStatus === 'END'
    ) {
      this.handleThreadMsg();
      return;
    }
    this.position = this.sessionRecordBoxRef.current.scrollTop;
  };

  // 展示模态框
  handleShowModal = async data => {
    const { IM } = this.props;
    const { fetchHist } = this.state;
    const msg_id = Number(data.msg_id);
    const query = {
      fromID: msg_id + 1,
      toID: msg_id - 21,
      max: 20,
      peerID: data.uid
    };
    this.getHistoryRecordStatus = 'LOADING';
    this.setState({
      visibleModal: true,
      currentSelectedUser: data,
      currentHistoryMsgId: query.toID,
      getHistoryRecordStatus: this.getHistoryRecordStatus
    });
    const record = await IM.getSessionRecord(query, fetchHist, false);
    this.handleThreadMsgCallback(record, true);
  };

  // 获取历史会话记录
  handleThreadMsg = async () => {
    const { IM } = this.props;
    const { currentHistoryMsgId, currentSelectedUser, fetchHist } = this.state;
    if (currentHistoryMsgId <= 0) return;
    // 立刻设置为false，防止用户滚动到顶部，再次触发 获取历史会话记录 的事件
    this.getHistoryRecordStatus = 'LOADING';
    // 获取当前的最后一条记录，当获取到历史记录以后，回滚到之前的位置，看 scrollToTargetPosition 方法
    // 提前获取，不放在setState中获取，因为是异步的，当更新时，历史记录可能已经返回了
    // eslint-disable-next-line
    this.scrollIntoViewElement = this.sessionRecordBoxRef.current.children[2];
    const query = {
      fromID: currentHistoryMsgId + 1,
      toID: currentHistoryMsgId - 21,
      max: 20,
      peerID: currentSelectedUser.uid
    };
    this.setState({
      currentHistoryMsgId: query.toID,
      getHistoryRecordStatus: this.getHistoryRecordStatus
    });
    const record = await IM.getSessionRecord(query, fetchHist, false);
    this.handleThreadMsgCallback(record);
  };

  // 拿到历史记录的回调
  handleThreadMsgCallback = async (data, isInit = false) => {
    const {
      state: { IM, csInfo },
      dispatch
    } = this.context;
    const { currentSelectedUser } = this.state;

    const cs_uid = IM.getLoginInfo().uid;
    const client = currentSelectedUser.uid;
    const { msg_list, fetch_hist } = data;

    // 需要将系统消息过滤掉
    const msgList = isEmpty(msg_list)
      ? []
      : msg_list.filter(item => item.type !== 1);
    const { sessionList } = this.state;

    this.getHistoryRecordStatus = 'END';
    const cs_uid_to_query = new Set();
    const list = msgList
      .map(item => {
        const uid = client === item.from_uid ? item.to_uid : item.from_uid;
        if (cs_uid != uid && uid && !csInfo[uid] && !cs_uid_to_query.has(uid)) {
          cs_uid_to_query.add(uid);
        }
        return {
          c: item.content,
          u: item.from_uid,
          // 后台返回的created字段是一个19位数字时间戳
          t: +item.created.toString().slice(0, 13),
          ct: MESSAGETYPE.find(it => it.type === item.type)?.ctype,
          id: uuid.v4(),
          state: item.state
        };
      })
      .reverse();
    this.setState(
      {
        fetchHist: fetch_hist,
        sessionList: isInit ? list || [] : list.concat(sessionList),
        getHistoryRecordStatus: this.getHistoryRecordStatus
      },
      () => {
        if (isInit) {
          // 如果是第一次获取，那么滚动到底部
          this.timer = setTimeout(() => {
            this.sessionRecordBoxRef.current.scrollTop = this.sessionRecordBoxRef.current.scrollHeight;
          }, 0);
        } else {
          // 否则就滚动到之前的位置。方便查看历史记录
          this.scrollIntoViewElement &&
            this.scrollIntoViewElement.scrollIntoView(true);
        }
      }
    );
    if (cs_uid_to_query.size !== 0) {
      let { data: cs_new } = await api.getCsInfo({
        app_uid: [...cs_uid_to_query]
      });
      cs_new = cs_new || [];
      const cs_to_merge = Object.create(null);
      cs_new.forEach(item => {
        cs_to_merge[item['app_uid']] = item;
      });
      dispatch({
        payload: {
          csInfo: {
            ...csInfo,
            ...cs_to_merge
          }
        }
      });
    }
  };

  // 点击模态框的回拨按钮
  handleCallBack = () => {
    this.setState({ visibleModal: false, sessionList: [] }, () => {
      this.props.IM.onDial(this.state.currentSelectedUser.uid);
    });
  };

  // 展示PreviewImage
  handleShowPreviewImage = source => {
    this.props.onChange({
      isShowPreviewImage: true,
      previewImageSource: source
    });
  };

  render() {
    const {
      column,
      pageNum,
      maxPage,
      visibleModal,
      currentSelectedUser,
      sessionList,
      dataSource,
      currentHistoryMsgId,
      getHistoryRecordStatus
    } = this.state;
    const { visible } = this.props;
    if (!visible) return null;
    return (
      <Layout style={{ position: 'relative' }}>
        <div className={styles.sessionrecord}>
          <Search onSubmit={this.search} />
          <div className={styles.table}>
            <Table
              dataSource={dataSource}
              columns={column}
              rowKey='id'
              bordered
              pagination={false}
              size='middle'
            />
          </div>
          <div className={styles.footer}>
            <Button
              style={{ marginRight: '20px' }}
              onClick={this.handlePrevPage}
              disabled={pageNum <= 1}>
              上一页
            </Button>
            <span className={styles.currentPage}>
              第&nbsp;
              {pageNum}
              &nbsp;页
            </span>
            <Button
              type='primary'
              onClick={this.handleNextPage}
              disabled={pageNum >= maxPage}>
              下一页
            </Button>
          </div>
        </div>
        <Modal
          visible={visibleModal}
          title={currentSelectedUser.uid}
          className='session-record-modal'
          width={800}
          onCancel={() =>
            this.setState({ visibleModal: false, sessionList: [] })
          }
          footer={null}
          closable
          maskClosable={false}
          keyboard>
          <div className={styles.sessionRecord}>
            <div
              className={styles.sessionRecord_box}
              ref={this.sessionRecordBoxRef}
              onScroll={this.handleScroll}>
              <div
                style={{
                  width: '100%',
                  lineHeight: '30px',
                  textAlign: 'center',
                  display:
                    getHistoryRecordStatus === 'LOADING' ? 'block' : 'none'
                }}>
                <Icon
                  type='loading1'
                  style={{ fontSize: '30px', color: '#d3d8de' }}
                  spin
                />
              </div>
              <div
                style={{
                  width: '100%',
                  lineHeight: '30px',
                  textAlign: 'center',
                  color: '#d3d8de',
                  display:
                    getHistoryRecordStatus === 'END' && currentHistoryMsgId <= 0
                      ? 'block'
                      : 'none'
                }}>
                没有更多历史记录了
              </div>
              {sessionList.map(item => (
                <ChatRoom
                  key={item.id}
                  message={item}
                  client={currentSelectedUser.uid}
                  customerAvatar={currentSelectedUser.avatar}
                  onClickImage={this.handleShowPreviewImage}
                  isRecord={true}
                />
              ))}
            </div>
            <div
              className={styles.sessionRecord_footer}
              onClick={this.handleCallBack}>
              <Icon type='Group'>
                <span style={{ marginLeft: '7px' }}>回拨</span>
              </Icon>
            </div>
          </div>
        </Modal>
      </Layout>
    );
  }
}
