export default class Queue {
  constructor() {
    this.queue = [];
  }
  task(delay, cb) {
    this.queue.push({
      delay,
      cb
    });
    return this;
  }

  start() {
    let _start = q => {
      if (q) {
        setTimeout(() => {
          q.cb();
          _start(this.queue.shift());
        }, q.delay);
      }
    };
    _start(this.queue.shift());
    return this;
  }
}
