import React from 'react';
import { useContext } from 'react';
import { Context } from '@context';

const TotalUnread = () => {
  const { state } = useContext(Context);
  const { totalUnread } = state;

  return (
    <span
      style={{ display: totalUnread > 0 ? 'block' : 'none' }}
      className='im-total-unread'>
      {+totalUnread > 999 ? '999+' : totalUnread}
    </span>
  );
};

export default React.memo(TotalUnread);
