import { message, Modal } from 'antd';
import { isEmpty } from 'lodash';
import WebIM from '@qtt/im-sdk';
import { getLoginParams } from '.';
import { isOffline } from '@utils';
import { STATE_RECALL, MESSAGETYPE } from '@constant';
import { initReport } from './shm_report';
import api from "@services/api";
// message 事件的类型
const Map = {
  'session:send:reply': [],
  'session:receive': [],
  'session:pick:reply': [],
  'session:history:reply': [],
  'session:close:reply': [],
  'session:switch:reply': [],
  'session:colleagues:reply': [],
  'session:setStatus:reply': [],
  'session:updateOffset:reply': [],
  'session:transferout': [],
  'session:transferin': [],
  'session:transferclose': [],
  'session:transferclose:actually': [],
  'session:dial:reply': [],
  'session:msgupdate:recall': [],
  'session:msgupdate:evaluate': []
};

// 触发
const emit = (type, ...args) => {
  const fns = Map[type];
  if (isEmpty(fns)) return;
  fns.forEach(fn => fn(...args));
};

// 监听
const on = (type, callback) => {
  if (type in Map && callback) {
    Map[type].push(callback);
  } else {
    console.log(`${type} type is not allowed`);
  }
};

const off = (type, fn) => {
  const fns = Map[type];
  if (isEmpty(fns)) return;
  const index = fns.indexOf(fn);
  if (index > -1) {
    fns.splice(index, 1);
  }
};

export default function createIm(callback) {
  // 获取会话历史的时候会用到
  let fetchHist = false;
  // 获取待接入列表需要的字段，初始的时候是 0, 当为false的时候表示已经没有信息了，不允许在此请求
  let queueSince = 0;
  // 会话列表
  let sessionList = [];
  // 登录信息
  let loginInfo = {};
  let api_token = null;

  // let status = 0;
  const params = getLoginParams();

  const isProduction = process.env.NODE_ENV === 'production' && process.env.IM_ENV !== 'test';
  params.host = isProduction ? WebIM.IM.PROD : WebIM.IM.TEST; // 配置环境参数：  IM.PROD 为生产环境  IM.TEST 为测试环境
  // eslint-disable-next-line
  const im = new WebIM(params);
  console.log('登录params:', params);

  if (isOffline()) {
    setTimeout(() => {
      callback([], '2');
    }, 0);
  } else {
    im.connect();
  }

  im.on('open', function (channel) {
    // WebSocket 连接成功
    console.log('链接成功', channel);
  });

  im.on('login', function (channel, payload) {
    // 登录成功
    // channel: WebSocket 通道标识。
    // 有登录结果才有 payload（包含会话信息），环信没有
    sessionList = payload.cs_offline_threads;
    api_token = payload.api_token;
    const uid = payload.login_info.uid;

    try {
      api.sentryExceptionMessageReport(sessionList, uid);
    } catch (error) {
      //
    }

    loginInfo = {
      csid: Object.keys(payload.login_info.csids)[0],
      uid,
      max_serve: payload.login_info.max_serve,
      status: `${payload.cs_status}`
    };
    initReport(uid);
    // 客服的在线状态
    // status = payload.cs_status;
    callback && callback(sessionList, loginInfo);
  });

  // 会话转出转入
  im.on('session', function (event) {
    const { type, data } = event;
    if (type === 3) {
      emit('session:transferclose', data);
    } else if (type === 4) {
      // 会话转出
      emit('session:transferout', data);
    } else if (type === 5) {
      // 会话转入
      emit('session:transferin', data);
    } else if (type === 18) {
      if (data && data.msg) {
        // 撤回消息
        if (data.msg.state === STATE_RECALL) {
          emit('session:msgupdate:recall', data);
        }
        // 用户评价
        if (data.msg.type === 8) {
          emit('session:msgupdate:evaluate', data);
        }
      }
    }
  });

  im.on('message', function (msg) {
    emit('session:receive', msg);
  });

  im.on('error', function (channel, msg) {
    if (msg === 'kicked') {
      Modal.info({
        title: '消息提示',
        content: '您已在别处登陆',
        okText: '确认',
        onOk: () => (window.location.href = 'https://imcms.innotechx.com')
      });
    }
  });

  im.on('close', function (channel, msg) {
    console.log('close:', msg);
    if (!isOffline()) {
      Modal.info({
        title: '消息提示',
        content: '您已断开链接，请重新登录',
        okText: '确认',
        onOk: () => (window.location.href = 'https://imcms.innotechx.com')
      });
    }
  });

  // eslint-disable-next-line

  const IM = {
    on,
    emit,
    off,
    // 发送文本 to: 用户id content: 消息内用 id: 这条消息的id
    sendText: (to, content, id, type = 0) => {
      const sendMsg = { type, content, to };
      if (type === 11) {
        sendMsg.ext = { fallback: '[图片]' };
      }
      im.send(sendMsg)
        .then(data => {
          const {
            err,
            result: { msg }
          } = JSON.parse(data.payload);
          if (err === 0) {
            emit('session:send:reply', {
              status: 'SUCCESS',
              id,
              lastContent:
                type === 0
                  ? msg.content
                  : MESSAGETYPE.find(it => it.type === type).content,
              lastTimeStamp: msg.created,
              msg_id: msg.msg_id
            });
          } else {
            throw '消息发送失败';
          }
        })
        .catch(err => {
          emit('session:send:reply', { status: 'FAIL', id });
        });
    },
    // 发送图片
    sendImage: (to, file, id, cb) => {
      return im
        .sendImage({
          to,
          file
        })
        .then(data => {
          const { urls, payload } = data;
          const {
            result: { msg }
          } = payload;
          cb(file, urls, id);
          emit('session:send:reply', {
            status: 'SUCCESS',
            id,
            lastContent: '[图片]',
            lastTimeStamp: msg.created,
            msg_id: msg.msg_id
          });
        })
        .catch(err => {
          message.error('图片上传失败');
        });
    },
    // 发送语音
    sendAudio: (to, file, id, cb) => {
      return im
        .sendAudio({
          to,
          file
        })
        .then(data => {
          const { urls, payload } = data;
          const {
            result: { msg }
          } = payload;
          cb(file, urls, id);
          emit('session:send:reply', {
            status: 'SUCCESS',
            id,
            lastContent: '[语音]',
            lastTimeStamp: msg.created,
            msg_id: msg.msg_id
          });
        })
        .catch(err => {
          message.error('语音上传失败');
        });
    },
    // 发送视频
    sendVedio: (to, content) => {
      return im.send({ type: 4, content, to, ext: { fallback: '[视频]' } });
    },
    // 发送商品
    sendGoods: (to, content) => {
      return im.send({ type: 5, content, to, ext: { fallback: '[商品]' } });
    },
    // 发送订单
    sendOrder: (to, content) => {
      return im.send({ type: 6, content, to, ext: { fallback: '[订单]' } });
    },
    // 发送活动
    sendActive: (to, content) => {
      return im.send({ type: 7, content, to, ext: { fallback: '[活动]' } });
    },
    // 发送退费挽留
    sendSaveUserRefund: (to, content, id) => {
      const type = 0;
      return im.send({ type, content, to, ext: { fallback: '[系统: 退费挽留]', showSaveUserRefund: true } }).then(data => {
        const {
          err,
          result: { msg }
        } = JSON.parse(data.payload);
        if (err === 0) {
          emit('session:send:reply', {
            status: 'SUCCESS',
            id,
            lastContent:
              type === 0
                ? msg.content
                : MESSAGETYPE.find(it => it.type === type).content,
            lastTimeStamp: msg.created,
            msg_id: msg.msg_id
          });
        } else {
          throw new Error('消息发送失败');
        }
      })
      .catch(err => {
        emit('session:send:reply', { status: 'FAIL', id });
      });
    },
    // 发送填写手机号码
    sendOutputPhone: (to, content, id) => {
      const type = 0;
      return im.send({ type, content, to, ext: { fallback: '[系统: 填写手机号码]', showUserPhone: true } }).then(data => {
        const {
          err,
          result: { msg }
        } = JSON.parse(data.payload);
        if (err === 0) {
          emit('session:send:reply', {
            status: 'SUCCESS',
            id,
            lastContent:
              type === 0
                ? msg.content
                : MESSAGETYPE.find(it => it.type === type).content,
            lastTimeStamp: msg.created,
            msg_id: msg.msg_id
          });
        } else {
          throw new Error('消息发送失败');
        }
      })
      .catch(err => {
        emit('session:send:reply', { status: 'FAIL', id });
      });
    },

    // 发送自定义消息(该方法为sendSaveUserRefund和sendOutputPhone的重构方法)
    sendCustomMessage: (to, content, id, options) => {
      const { type = 0, fallback = '', ...args } = options || {};
      return im.send({ type, content, to, ext: { fallback, ...args } }).then(data => {
        const {
          err,
          result: { msg }
        } = JSON.parse(data.payload);
        if (err === 0) {
          emit('session:send:reply', {
            status: 'SUCCESS',
            id,
            lastContent:
              type === 0
                ? msg.content
                : MESSAGETYPE.find(it => it.type === type).content,
            lastTimeStamp: msg.created,
            msg_id: msg.msg_id
          });
        } else {
          throw new Error('消息发送失败');
        }
      })
      .catch(err => {
        emit('session:send:reply', { status: 'FAIL', id });
      });
    },
    // 获取会话记录
    // isInit表示 刚打开这个用户会话 并是第一次获取历史会话记录
    getSessionRecord: (option, isInit, needEmit = true) => {
      // 当获取这个用户的会话记录以后，会将response中的 fetch_hist 赋值给 fetchHist，
      // 下次再次获取会话记录的时候在传给后端
      fetchHist = isInit ? false : fetchHist;
      const { fromID, toID, max, peerID } = option;
      return im
        .getMessages({
          fetch_hist: fetchHist,
          from_msg_id: fromID,
          max,
          to_msg_id: toID,
          session: {
            // 商家id
            csid: loginInfo.csid,
            // 买家id
            peer_uid: peerID,
            // 客服id
            uid: loginInfo.uid
          }
        })
        .then(data => {
          const { payload } = data;
          const {
            err,
            result: { msg_list, fetch_hist }
          } = JSON.parse(payload);

          if (err === 0) {
            fetchHist = fetch_hist;
            if (needEmit) {
              emit(
                'session:history:reply',
                msg_list ? msg_list.filter(item => item.type !== 1) : [],
                isInit
              );
            }
            return { msg_list, fetch_hist };
          } else {
            throw '历史记录获取失败';
          }
        })
        .catch(() => console.error('历史记录获取失败'));
    },
    // 手动介入会话
    onPick: uid => {
      im.pick({ csid: loginInfo.csid, peer: { uid } })
        .then(data => {
          const { err, thread } = JSON.parse(data.payload);
          if (err === 0) {
            emit('session:pick:reply', thread);
          } else {
            throw '手动接入会话失败，请刷新后再试';
          }
        })
        .catch(() => message.error('手动接入会话失败，请刷新后再试'));
    },
    // 手动关闭待接入会话
    onCloseWaitingSession: (uid, cb) => {
      im.endq({ csid: loginInfo.csid, peer: { uid } })
        .then(data => {
          const { err } = JSON.parse(data.payload);
          if (err === 0) {
            cb && cb();
          } else {
            throw '手动关闭会话失败';
          }
        })
        .catch(() => message.error('手动关闭会话失败'));
    },
    // 手动关闭会话
    onClose: uid => {
      im.end({ csid: loginInfo.csid, peer: { uid } })
        .then(data => {
          const { err, result } = JSON.parse(data.payload);
          if (err === 0) {
            emit('session:close:reply', result);
          } else {
            throw '手动关闭会话失败';
          }
        })
        .catch(() => message.error('手动关闭会话失败'));
    },
    // 获取在线的客服同事
    onColleagues: () => {
      im.colleagues({ csid: loginInfo.csid })
        .then(data => {
          const { err, uids } = JSON.parse(data.payload);
          if (err === 0) {
            emit('session:colleagues:reply', 'SUCCESS', uids || []);
          } else {
            throw '获取在线客服失败';
          }
        })
        .catch(() => {
          message.error('获取在线客服失败');
          emit('session:colleagues:reply', 'FAIL');
        });
    },
    // 转接会话给同事
    onSwitch: (uid, transferID) => {
      im.switch({ csid: loginInfo.csid, peer: { uid }, to_cs_uid: transferID })
        .then(data => {
          const { err, threads } = JSON.parse(data.payload);
          if (err === 0) {
            emit('session:switch:reply', threads);
          } else {
            message.warning('会话转接失败');
          }
        })
        .catch(() => message.error('会话转接失败'));
    },
    // 设置在线状态
    onSetStatus: status => {
      if (!im.ws) {
        return im.connect().then(() => {
          im.setStatus({ status: Number(status) }).then(() => {
            window.location.reload();
          });
        });
      }
      return im.setStatus({ status: Number(status) })
        .then(data => {
          window.location.reload();
          const { err, result } = JSON.parse(data.payload);
          if (err === 0) {
            emit('session:setStatus:reply', 'SUCCESS', result || []);
          } else {
            throw '当前状态设置失败';
          }
        })
        .catch(() => {
          message.error('当前状态设置失败');
          emit('session:setStatus:reply', 'FAIL');
        });
    },
    // 更新已读位置
    onUpdateOffset: (msg_id, peerID) => {
      im.updateOffset({
        msg_id,
        session: {
          csid: loginInfo.csid,
          peer_uid: peerID,
          uid: loginInfo.uid
        }
      })
        .then(data => {
          const { err } = JSON.parse(data.payload);
          if (err === 0) {
            emit('session:updateOffset:reply', 'SUCCESS', peerID);
          } else {
            throw '更新已读位置失败';
          }
        })
        .catch(error => {
          console.log(error);
          emit('session:updateOffset:reply', 'FAIL');
        });
    },
    // 回拨
    onDial: uid => {
      im.dial({ csid: loginInfo.csid, peer: { uid } })
        .then(data => {
          const { err, thread } = JSON.parse(data.payload);

          // 0 表示成功 11 表示这个用户正在通自己进行会话
          if (err === 0 || err === 11) {
            emit('session:dial:reply', {
              ...thread,
              peer: { ...thread.peer, uid }
            });
          } else if (err === 10) {
            message.error(
              '当前用户正在和其他客服进行会话，暂时无法进行回拨操作'
            );
          } else {
            message.error('回拨失败，暂时无法进行回拨操作');
          }
        })
        .catch(() => {
          message.error('回拨失败，暂时无法进行回拨操作');
        });
    },
    queryMsg: params => {
      return im
        .queryMsg({
          ...params,
          csid: loginInfo.csid
        })
        .then(data => {
          const { err, msg, payload } = data;
          if (err === 0) {
            return payload;
          }
          throw msg;
        })
        .catch(error => {
          throw error;
        });
    },
    queryMsgWhenNoCond: since => {
      return im
        .queryMsgWhenNoCond({
          since,
          inbox: {
            csid: loginInfo.csid,
            uid: loginInfo.uid
          }
        })
        .then(data => {
          const { err, result } = data;
          if (err === 0) {
            return result.threads;
          }
        })
        .catch(error => {
          throw error;
        });
    },
    // 获取待接入列表 init 表示初始化加载
    onQueue: since => {
      return im
        .queue({ n: 20, since, csid: loginInfo.csid })
        .then(data => {
          const { queued_threads, more } = JSON.parse(data.payload);
          return {
            queued_threads: queued_threads || [],
            more
          };
        })
        .catch(error => { });
    },
    // 保持会话
    keepSession: uid => {
      im.keepSession({ csid: loginInfo.csid, peer: { uid } }).catch(
        error => { }
      );
    },
    // 查询用户设备信息
    queryMedia: uid => {
      im.queryMedia({ uid })
        .then(data => {
          const { err, devices } = data;
          if (err === 0) {
            return devices;
          }
        })
        .catch(error => { });
    },
    getLoginInfo: () => {
      return loginInfo;
    },
    // 撤回
    recall: (uid, msg_id) => {
      /** 撤回需要点击两次的问题
       * .then(() => {
        emit('session:msgupdate:recall', {
          msg: {
            msg_id,
            state: 8,
          }
        });
      })
       */
      im.recall({
        inbox: {
          csid: loginInfo.csid,
          uid: loginInfo.uid
        },
        peer: { uid },
        msg_id
      }).catch(error => {
        message.error('撤回失败，请重试');
      });
    },
    closeWs: () => {
      im.close();
    },
    //发送用户评价
    sendEvaluation: to => {
      im.send({ type: 8, to })
        .then(data => {
          const { err, msg, result } = JSON.parse(data.payload);
          if (err !== 0) {
            throw msg;
          }
          emit('session:receive', result.msg, false);
        })
        .catch(err => {
          message.error(err);
        });
    },
    // 用户评价
    evaluate: (uid, msg_id, evaluation) => {
      im.evaluate({
        inbox: {
          csid: loginInfo.csid,
          uid: loginInfo.uid
        },
        peer: { uid },
        msg_id,
        evaluation
      }).catch(error => {
        message.error('邀请用户评价失败，请重试');
      });
    },

    queryTodayMsg: () => {
      return im
        .queryTodayMsg({
          "app": 4,
          "csid": loginInfo.csid,
          "csuid": loginInfo.uid,
          "offset": 0,
          "count": 500
        }, api_token)
        .then(data => {
          return data && data.result
        })
    },
    // /**
    //  * 根据关键词搜索当前会话历史记录
    //  */
    searchAggsUser: (data) => {
      return im.searchAggsUser(data);
    },
  };
  return IM;
}
