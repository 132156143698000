import Notify from '@wcjiang/notify';
import audioSrc from '../assets/toastAudio.mp3';
import icon from '../assets/images/icon.png';

const notify = new Notify({
  message: '',
  effect: 'scroll', // flash | scroll, Flashing or scrolling
  onclick: () => {
    notify.close();
  },
  interval: 600,
  notification: {
    title: '',
    icon,
    body: ''
  }
});

notify.setURL(audioSrc);
export default notify;
