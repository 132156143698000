import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import styles from './index.less';
import defaultAvator from '../../assets/images/avatar.png';
import { Icon } from 'antd';

import Image from './Image';
import File from './File';
import Order from './Order';
import RecallWrap from './RecallWrap';

import { EMOJI, STATE_RECALL } from '../../constant';
import { Context } from '@context';
import { VideoSession } from './Video';

export default class ChatRoom extends PureComponent {
  static contextType = Context;

  static propTypes = {
    message: PropTypes.object,
    client: PropTypes.string,
    customerAvatar: PropTypes.string,
    onClickImage: PropTypes.func,
    isRecord: PropTypes.bool
  };

  static defaultProps = {
    message: {},
    client: '',
    customerAvatar: '',
    onClickImage: () => {},
    isRecord: false
  };

  // 防止用户输入一些恶意的内容，比如说输入一个<script>这样的一个标签，注入脚本
  preventXSS = str => {
    return str.replace(/\</i, '&lt;').replace(/\>/i, '&gt;');
  };

  // 计算文本格式，并将emoji替换成img
  computedText = text => {
    // 如果不含有emoji就直接返回 text
    if (!/\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/.test(text))
      return this.preventXSS(text);
    // 将text中的emoji替换成img
    return this.preventXSS(text).replace(
      /\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/gi,
      match => {
        const targetObj = EMOJI.find(item => item.name === match);
        if (targetObj) {
          // eslint-disable-next-line
          const src = require(`../Chatemoji/faces/${targetObj.src}`);
          // eslint-disable-next-line
          return `<img class="emoji" src="${src}" alt="${match}" width="25" height="25" style="vertical-align: middle;" />`;
        }
        return match;
      }
    );
  };

  renderContent = message => {
    const { onClickImage } = this.props;
    const { ct, c, done } = message;
    console.log('message: ', message);
    let result = null;
    if (ct === 'txt') {
      const arr = this.computedText(c).split(/\n/);
      return (
        <div className={classnames(styles.content, 'index_chatbox_content')} style={{ maxWidth: '80%' }}>
          {arr.map((item, index) => (
            // eslint-disable-next-line
            <p
              key={index}
              className={styles.textPlain}
              dangerouslySetInnerHTML={{ __html: item }}
            />
          ))}
        </div>
      );
    }
    if (ct === 'img') {
      const content = typeof c === 'string' ? JSON.parse(c) : c;
      result = (
        <div className={styles.content}>
          <Image content={content} done={done} onClickImage={onClickImage} />
        </div>
      );
    } else if (ct === 'radio') {
      const { urls, url } = typeof c === 'string' ? JSON.parse(c) : c;
      const src = url ? url : urls[0];
      result = (
        <div className={styles.audio}>
          <audio src={src} controls preload='true'>
            您的设备不支持播放该音频
          </audio>
        </div>
      );
    } else if (ct === 'file') {
      const content = typeof c === 'string' ? JSON.parse(c) : c;
      result = (
        <div className={styles.content}>
          <File content={content} done={done} />
        </div>
      );
    } else if (ct === 'order') {
      const content = typeof c === 'string' ? JSON.parse(c) : c;
      result = (
        <div className={styles.content}>
          <Order content={content} />
        </div>
      );
    } else if (ct === 'goods') {
      const content = typeof c === 'string' ? JSON.parse(c) : c;
      result = (
        <div className={styles.content}>
          <Order content={content} mode={false} />
        </div>
      );
    } else if (ct === 'video') {
      const content = typeof c === 'string' ? JSON.parse(c) : c;
      const url = content?.urls?.[0] || '';
      result = url ? (
        <div className={styles.content}>
          <VideoSession url={url} />
        </div>
      ) : <div className={styles.content}>暂无视频内容</div>;
    }
    return result;
  };

  renderCsInfo(u) {
    const { client } = this.props;
    const {
      state: { IM, csInfo }
    } = this.context;
    const cs_uid = IM.getLoginInfo().uid;

    if (u === client || u === cs_uid) return null;
    if (!csInfo[u]) return null;
    return (
      <span className={styles.chatbox_con_cs_info}>{csInfo[u].nick || u}</span>
    );
  }

  who = () => {
    const {
      message: { u }
    } = this.props;
    const {
      state: { IM, csInfo }
    } = this.context;
    const cs_uid = IM.getLoginInfo().uid;

    let who = null;
    if (cs_uid === u || !u) {
      who = '你';
    } else {
      if (csInfo[u]) {
        who = csInfo[u].nick || u;
      } else {
        who = u;
      }
    }
    return who;
  };

  renderRecallInfo = () => {
    const {
      message: { t }
    } = this.props;
    return (
      <div className={styles.recall_msg}>
        {this.who()}撤回了一条消息(
        {moment(t).format('YYYY-MM-DD HH:mm:ss')})
      </div>
    );
  };

  renderEvaluationInfo = () => {
    const {
      message: { t }
    } = this.props;
    return (
      <div className={styles.recall_msg}>邀请用户进行服务评价({moment(t).format('YYYY-MM-DD HH:mm:ss')})
      </div>
    );
  };

  render() {
    const { message, client, customerAvatar, isRecord } = this.props;
    const { u, t, for_id, state, ct, fail } = message;
    const {
      state: { customerInfo }
    } = this.context;
    // 撤回
    if (state === STATE_RECALL) {
      return <div className={styles.chatbox}>{this.renderRecallInfo()}</div>;
    }
    // 用户评价
    if (ct === 'evaluate') {
      return (
        <div className={styles.chatbox}>{this.renderEvaluationInfo()}</div>
      );
    }
    return (
      <div data-msg-id={`${message.id}`} className={classnames(styles.chatbox, 'index_chatbox_item')}>
        <img
          src={
            (u === client ? customerAvatar : customerInfo.logo) || defaultAvator
          }
          alt='avatar'
          className={classnames({
            [styles.chatbox_avatar]: true,
            [styles.merchant]: u !== client,
            [styles.customer]: u === client
          })}
        />
        <div
          className={classnames({
            [styles.chatbox_con]: true,
            [styles.merchant]: u !== client,
            [styles.customer]: u === client
          })}>
          <div className={styles.chatbox_con_time}>
            {this.renderCsInfo(u)}
            {moment(t).format('YYYY-MM-DD HH:mm:ss')}
            {!isRecord && <RecallWrap message={message} client={client} />}
          </div>
          <div className={styles.chatbox_con_content}>
            {this.renderContent(message)}
            <div
              className={classnames({
                [styles.loading]: true,
                [styles.hide]: !fail
              })}>
              <Icon type="exclamation-circle" style={{ color: '#f5222d' }}></Icon>
              {/* <Icon
                type='loading1'
                style={{ fontSize: 22, color: '#999' }}
                spin
              /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
