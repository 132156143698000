const emojiNative = [
  '😊',
  '😃',
  '😉',
  '😮',
  '😋',
  '😎',
  '😡',
  '😖',
  '😢',
  '🙁',
  '😭',
  '😓',
  '😇',
  '😬',
  '😏',
  '😨',
  '😴',
  '😐',
  '😕',
  '😷',
  '😂',
  '😒',
  '😑',
  '💖',
  '💔',
  '🌙',
  '🌟',
  '🌞',
  '🌈',
  '😍',
  '😚',
  '💋',
  '🌹',
  '🍂',
  '👍',
];

const emojiMap = {
  '[):]': 'ee_1.png',
  '[:D]': 'ee_2.png',
  '[;)]': 'ee_3.png',
  '[:-o]': 'ee_4.png',
  '[:p]': 'ee_5.png',
  '[(H)]': 'ee_6.png',
  '[:@]': 'ee_7.png',
  '[:s]': 'ee_8.png',
  '[:$]': 'ee_9.png',
  '[:(]': 'ee_10.png',
  "[:'(]": 'ee_11.png',
  '[:|]': 'ee_18.png',
  '[(a)]': 'ee_13.png',
  '[8o|]': 'ee_14.png',
  '[8-|]': 'ee_15.png',
  '[+o(]': 'ee_16.png',
  '[@o)]': 'ee_12.png',
  '[|-)]': 'ee_17.png',
  '[*-)]': 'ee_19.png',
  '[:-#]': 'ee_20.png',
  '[:-*]': 'ee_22.png',
  '[^o)]': 'ee_21.png',
  '[8-)]': 'ee_23.png',
  '[(|)]': 'ee_24.png',
  '[(u)]': 'ee_25.png',
  '[(S)]': 'ee_26.png',
  '[(*)]': 'ee_27.png',
  '[(#)]': 'ee_28.png',
  '[(R)]': 'ee_29.png',
  '[({)]': 'ee_30.png',
  '[(})]': 'ee_31.png',
  '[(k)]': 'ee_32.png',
  '[(F)]': 'ee_33.png',
  '[(W)]': 'ee_34.png',
  '[(D)]': 'ee_35.png',
};

const emojiCodeMap = {
  '[):]': '[微笑]',
  '[:D]': '[大笑]',
  '[;)]': '[顽皮]',
  '[:-o]': '[尴尬]',
  '[:p]': '[舔嘴]',
  '[(H)]': '[帅气]',
  '[:@]': '[愤怒]',
  '[:s]': '[无奈]',
  '[:$]': '[害羞]',
  '[:(]': '[难过]',
  "[:'(]": '[大哭]',
  '[:|]': '[瞪眼]',
  '[(a)]': '[开心]',
  '[8o|]': '[龇牙]',
  '[8-|]': '[坏笑]',
  '[+o(]': '[呕吐]',
  '[@o)]': '[滴汗]',
  '[|-)]': '[困了]',
  '[*-)]': '[不屑]',
  '[:-#]': '[闭嘴]',
  '[:-*]': '[气氛]',
  '[^o)]': '[偷笑]',
  '[8-)]': '[低头看]',
  '[(|)]': '[爱心]',
  '[(u)]': '[心碎]',
  '[(S)]': '[月亮]',
  '[(*)]': '[星星]',
  '[(#)]': '[太阳]',
  '[(R)]': '[彩虹]',
  '[({)]': '[好色]',
  '[(})]': '[亲嘴]',
  '[(k)]': '[红唇]',
  '[(F)]': '[玫瑰]',
  '[(W)]': '[凋谢]',
  '[(D)]': '[点赞]',
};

export default Object.entries(emojiMap).map(([k, v], index) => ({
  name: emojiNative[index],
  src: v,
  text: emojiCodeMap[k],
  code: k,
}));
