module.exports = {
  path: 'faces/',
  map: {
    '😊': 'ee_1.png',
    '😃': 'ee_2.png',
    '😉': 'ee_3.png',
    '😮': 'ee_4.png',
    '😋': 'ee_5.png',
    '😎': 'ee_6.png',
    '😡': 'ee_7.png',
    '😖': 'ee_8.png',
    '😢': 'ee_9.png',
    '🙁': 'ee_10.png',
    '😭': 'ee_11.png',
    '😓': 'ee_12.png',
    '😇': 'ee_13.png',
    '😬': 'ee_14.png',
    '😏': 'ee_15.png',
    '😨': 'ee_16.png',
    '😴': 'ee_17.png',
    '😐': 'ee_18.png',
    '😕': 'ee_19.png',
    '😷': 'ee_20.png',
    '😂': 'ee_21.png',
    '😒': 'ee_22.png',
    '😑': 'ee_23.png',
    '💖': 'ee_24.png',
    '💔': 'ee_25.png',
    '🌙': 'ee_26.png',
    '🌟': 'ee_27.png',
    '🌞': 'ee_28.png',
    '🌈': 'ee_29.png',
    '😍': 'ee_30.png',
    '😚': 'ee_31.png',
    '💋': 'ee_32.png',
    '🌹': 'ee_33.png',
    '🍂': 'ee_34.png',
    '👍': 'ee_35.png',
  },
};
