import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Icon } from 'antd';
import { Context } from '@context';
import styles from './index.less';

const RecallWrap = ({ children, message, client }) => {
  const { state } = useContext(Context);
  const { IM } = state;
  const [canRecall, setCanRecall] = useState(false);

  useEffect(() => {
    const { u, t } = message;
    const cs_uid = IM.getLoginInfo().uid;
    const five_minutes = 1000 * 60 * 5;
    const now = Date.now();

    if ((!!u && u !== cs_uid) || now - t > five_minutes) {
      setCanRecall(false);
      return;
    }
    setCanRecall(true);
    const timer = setTimeout(() => {
      setCanRecall(false);
    }, five_minutes - (now - t));
    return () => clearTimeout(timer);
  }, []);

  const recall = useCallback(() => {
    IM.recall(client, message.id);
  }, []);

  if (canRecall) {
    return (
      <a onClick={recall} className={styles.recall} >
        <Icon type='undo' className={styles.recall_icon} />
        撤回
      </a>
    );
  }
  return null;
};

export default React.memo(RecallWrap);
