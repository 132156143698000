import React, { useEffect, useState, useCallback } from 'react';
import { Input, Icon, Tooltip } from 'antd';
import api from '@services/api';
import AutoHeightIframe from './AutoHeightIframe';
import defaultAvatar from '../../assets/images/avatar.png';
import styles from './index.less';
const { TextArea } = Input;

const UserInfo = ({ uid }) => {
  const [editing, setEditing] = useState(false);
  const [mark, setMark] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await api.queryMark({ customer_id: uid });
      setMark(data.mark);
    };
    fetchData();
  }, [uid]);

  const data = {};
  const { avatar = defaultAvatar, nickname = '--', sex = '--' } = data;

  const onChange = useCallback(e => {
    e.persist();
    setMark(e.target.value);
  }, []);

  const toggle = () => {
    if (editing) {
      api.postMark({ mark, customer_id: uid });
    }
    setEditing(!editing);
  };

  return (
    <div className={styles['userinfo']} style={{ height: '99%' }}>
      {/* <div className={styles['userinfo-header']}>
        <img
          src={avatar}
          alt='avatar'
          className={styles['userinfo-header-avatar']}
        />
        <div className={styles['userinfo-header-name']}>{nickname}</div>
      </div> */}
      <div className={styles['userinfo-con']} style={{ height: '100%' }}>
        {/* <div className={styles['userinfo-con-item']}>
          <span className={styles['userinfo-con-item-title']}>性别</span>
          <div className={styles['userinfo-con-item-text']}>{sex}</div>
        </div> */}
        <AutoHeightIframe uid={uid} />
        <div className={styles['userinfo-con-item']}>
          <span className={styles['userinfo-con-item-title']}>备注</span>
          <div className={styles['userinfo-con-item-text']}>
            {editing ? (
              <TextArea
                value={mark}
                onChange={onChange}
                onBlur={toggle}
                maxLength={500}
                autosize={{ minRows: 3, maxRows: 30 }}
                style={{ fontSize: 12 }}
              />
            ) : (
              <>
                {mark}
                <Tooltip title='编辑备注'>
                  <Icon
                    type='edit'
                    className={styles['userinfo-con-item-icon']}
                    onClick={toggle}
                  />
                </Tooltip>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(UserInfo);
