import React, { useContext } from 'react';
import { Table, Icon, Switch } from 'antd';
import { Context } from '@context';
import { setIMSettingStore } from '@utils/storage';
import data from './data';
import styles from './index.module.less';

const Setting = () => {
  const { state, dispatch } = useContext(Context);
  const updateSystemInfo = payload => {
    dispatch({
      payload
    });
    setIMSettingStore(payload);
  };

  const columns = [
    {
      title: '功能',
      dataIndex: 'type',
      render: text => text
    },
    {
      title: '说明',
      dataIndex: 'desc',
      align: 'left',
      render: text => {
        return (
          <>
            <Icon type='info-circle' />
            <span style={{ marginLeft: 10 }}>{text}</span>
          </>
        );
      }
    },
    {
      title: '操作',
      key: 'operation',
      dataIndex: 'name',
      render: text => {
        return (
          <Switch
            checkedChildren='开'
            unCheckedChildren='关'
            checked={state[text]}
            onChange={() => updateSystemInfo({ [text]: !state[text] })}
          />
        );
      }
    }
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Table
          columns={columns}
          dataSource={data}
          bordered
          pagination={false}
        />
      </div>
    </div>
  );
};

export default React.memo(Setting);