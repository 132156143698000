import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Checkbox } from 'antd';
import { Context } from '@context';
import styles from './index.less';

const KeepSession = ({ uid }) => {
  const { state, dispatch } = useContext(Context);
  const { keepSession, IM } = state;
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(keepSession.indexOf(uid) === -1 ? false : true);
  }, [uid]);

  const updateKeepSession = useCallback(
    e => {
      const checked = e.target.checked;
      setChecked(checked);
      dispatch({
        type: checked ? 'ADD_KEEP_SESSION' : 'REMOVE_KEEP_SESSION',
        payload: uid
      });
      if (checked) {
        IM.keepSession(uid);
      }
    },
    [uid]
  );

  return (
    <div className={styles['keep-session']}>
      <Checkbox checked={checked} onChange={updateKeepSession}>
        保持会话
      </Checkbox>
    </div>
  );
};

export default React.memo(KeepSession);
