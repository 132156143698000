import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './index.less';

export default class order extends PureComponent {
  static propTypes = {
    content: PropTypes.object,
    // 如果为true 表示订单模式 否则就是商品模式
    mode: PropTypes.bool
  };

  static defaultProps = {
    content: {}
  };

  constructor() {
    super();
    this.state = {};
  }

  openUrl = url => {
    window.open(url);
  };

  render() {
    const {
      content: {
        // 订单ID 或者 商品id
        id = '',
        // 标题
        title = '',
        // 价格
        price = '',
        // 图片地址
        thumbImage = '',
        // 销售状态
        status = '',
        url = ''
      }
    } = this.props;
    return (
      <div className={styles.order_box} onClick={() => this.openUrl(url)}>
        <div className={styles.order}>
          订单编号：
          {id}
        </div>
        <div className={styles.desc}>
          <img src={thumbImage} alt='订单' className={styles.desc_image} />
          <div className={styles.desc_con}>
            <div className={styles.desc_con_title}>{title}</div>
            <div className={styles.desc_con_info}>
              <span className={styles.desc_con_info_amount}>{price}</span>
              <span className={styles.desc_con_info_afterSaleStatus}>
                {status}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
