export const serialize = c => {
  return JSON.stringify(c);
};

export const dserialize = c => {
  let ret = null;
  try {
    ret = JSON.parse(c);
  } catch (error) {
    ret = [
      {
        text: c
      }
    ];
  }
  return ret;
};
