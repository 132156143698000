import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Modal, Button, Checkbox } from 'antd';
import {
  ReportCustomerServiceScreenSubCategoryNameClick,
} from '@shm/interface/lib/reportevents/frontend/customerservicescreen';
import { isEmpty } from 'lodash';
import api from '@services/api';
import { Context } from '@context';
import Draggable from '@components/Draggable';
import styles from './index.less';

const SessionTerminator = ({ uid }) => {
  const { state, dispatch } = useContext(Context);
  const { IM, showSessionTerminator, closeSession} = state;

  const [ids, setIds] = useState([]);
  const [candidateIds, setCandidateIds] = useState([]);
  const [ifAutoClosed, setIfAutoClosed] = useState(false);

  useEffect(() => {
    if (~Object.keys(closeSession).indexOf(uid)) {
      setIfAutoClosed(true);
      if (!showSessionTerminator) {
        dispatch({
          payload: {
            showSessionTerminator: true
          }
        });
      }
    } else {
      setIfAutoClosed(false);
    }
  }, [uid, closeSession]);

  useEffect(() => {
    const query = async () => {
      const { data } = await api.queryConsultCate({
        page_size: 100,
        page: 1
      });

      if (!isEmpty(data)) {
        setCandidateIds(
          data.map(({ id, name }) => {
            return { label: name, value: id };
          })
        );
      }
    };
    query();
  }, []);

  const cancel = () => {
    dispatch({
      payload: {
        showSessionTerminator: false
      }
    });
  };

  const onOk = () => {
    if (ifAutoClosed) {
      IM.emit('session:transferclose:actually', closeSession[uid]);
      dispatch({
        type: 'REMOVE_CLOSE_SESSION',
        payload: uid
      });
    } else {
      IM.onClose(uid);
    }
    if (ids.length !== 0) {
      // api.postConsultCate({
      //   id: ids
      // });
      const csUserID = IM.getLoginInfo().uid;
      ids.forEach((catID) => {
        ReportCustomerServiceScreenSubCategoryNameClick({
          category_id: catID,
          sender_id: uid,
          customer_service_id: csUserID,
        });
      });
    }
    cancel();
    setIds([]);
  };

  const onChange = useCallback(checkedValues => {
    setIds(checkedValues);
  }, []);

  const titleNode = ifAutoClosed ? (
    <>
      与<span className={styles['session-terminator-uid']}>{uid}</span>
      会话自动关闭
    </>
  ) : (
    <>
      关闭与<span className={styles['session-terminator-uid']}>{uid}</span>会话
    </>
  );

  const wrapClassName = 'session-terminator-wrap';

  const title = (
    <Draggable
      visible={showSessionTerminator}
      title={titleNode}
      wrapClassName={wrapClassName}
    />
  );

  return (
    <Modal
      visible={showSessionTerminator}
      title={title}
      onOk={onOk}
      onCancel={cancel}
      width={800}
      mask={false}
      maskClosable={false}
      wrapClassName={wrapClassName}
      afterClose={() => {
        document.querySelector(`.${wrapClassName}`).style.transform =
          'translate(0px, 0px)';
      }}
      bodyStyle={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}
      className='modal'
      footer={[
        <Button key='cancel' onClick={cancel}>
          取消
        </Button>,
        <Button key='submit' type='primary' onClick={onOk}>
          确认
        </Button>
      ]}>
      <div className={styles['consult-list']}>
        {!isEmpty(candidateIds) ? (
          <>
            <div className={styles['consult-list-title']}>
              请填写客户咨询类别:
            </div>
            <Checkbox.Group
              onChange={onChange}
              value={ids}
              className={styles['consult-list-checkbox']}>
              {candidateIds.map(({ label, value }) => (
                <div
                  className={styles['session-terminator-item']}
                  key={value}
                  title={label}>
                  <Checkbox value={value}>{label}</Checkbox>
                </div>
              ))}
            </Checkbox.Group>
          </>
        ) : (
          <>确认关闭当前会话？</>
        )}
      </div>
    </Modal>
  );
};

export default React.memo(SessionTerminator);
